
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import {AdminSidebarComponent} from './layout/sidebar/admin-sidebar/admin-sidebar.component';
import {AdminLoginComponent} from './login/admin-login/admin-login.component';
import { StaffLoginComponent } from './login/staff-login/staff-login.component';
import { StudentLoginComponent } from './login/student-login/student-login.component';
import {AdminDashboardComponent} from './layout/dashboard/admin-dashboard/admin-dashboard.component';
import {AdminMasterComponent} from './admin/admin-master/admin-master.component';
import { StaffMasterComponent } from './staff/staff-master/staff-master.component';
import {StaffSidebarComponent} from './layout/sidebar/staff-sidebar/staff-sidebar.component';
import {StaffDashboardComponent} from './layout/dashboard/staff-dashboard/staff-dashboard.component';
import { StudentMasterComponent } from './student/student-master/student-master.component';
import{StudentSidebarComponent} from './layout/sidebar/student-sidebar/student-sidebar.component';
import {StudentDashboardComponent} from './layout/dashboard/student-dashboard/student-dashboard.component';
import { StudentsDashboardComponent } from './student/students-dashboard/students-dashboard.component';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { CreateStaffComponent } from './admin/create-staff/create-staff.component';
import { ManageStaffComponent } from './admin/manage-staff/manage-staff.component';
import { AdminStudentlistComponent } from './admin/admin-studentlist/admin-studentlist.component';
import { AdminEditstudentComponent } from './admin/admin-editstudent/admin-editstudent.component';
import { UploadStudentComponent } from './admin/upload-student/upload-student.component';
import { StudentListComponent } from './staff/student-list/student-list.component';
import { SearchStudentComponent } from './staff/search-student/search-student.component';
import { DataTablesModule } from 'angular-datatables';
import {Ng2SearchPipeModule} from 'ng2-search-filter';
import {Ng2OrderModule} from 'ng2-order-pipe';
import {NgxPaginationModule} from 'ngx-pagination';
import { StudentsFormComponent } from './student/students-form/students-Form.component';
import { StaffResetpasswordComponent } from './staff/staff-resetpassword/staff-resetpassword.component';
import {AcademicTermComponent} from './student/academic-term/academic-term.component';
import { RequestdocumentComponent } from './staff/requestdocument/requestdocument.component';
import { DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { InfoComponent } from './student/info/info.component';

import { LoaderService } from './services/loader.service';
import { LoaderInterceptor } from './interceptors/loader-interceptor.service';
import { MyLoaderComponent } from './components/my-loader/my-loader.component';
import { StudentTimesheetComponent } from './student/student-timesheet/student-timesheet.component';
import { StudentWorkstudyComponent } from './student/student-workstudy/student-workstudy.component';

@NgModule({
  declarations: [
    AppComponent,   
    HeaderComponent,
    FooterComponent, 
    AdminSidebarComponent,
    AdminLoginComponent,
    AdminDashboardComponent,
    AdminMasterComponent,
    StaffMasterComponent,
    StaffLoginComponent,
    StaffSidebarComponent,
    StaffDashboardComponent,
    StudentLoginComponent,
    StudentMasterComponent,
    StudentDashboardComponent,
    StudentSidebarComponent,
    StudentsDashboardComponent,
    CreateStaffComponent,
    ManageStaffComponent,
    AdminStudentlistComponent,
    AdminEditstudentComponent,
    UploadStudentComponent,
    StudentListComponent,
    SearchStudentComponent,
    StudentsFormComponent,
    StaffResetpasswordComponent,
    AcademicTermComponent,
    RequestdocumentComponent,
    InfoComponent,
    MyLoaderComponent,
    StudentTimesheetComponent,
StudentWorkstudyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
   DataTablesModule,
   Ng2SearchPipeModule,
Ng2OrderModule,
NgxPaginationModule,
BrowserAnimationsModule,
BsDatepickerModule.forRoot(),
ModalModule.forRoot()

  ],
  bootstrap: [AppComponent],
  providers: [
    LoaderService,{ provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    DatePipe],
})
export class AppModule { }
