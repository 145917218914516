export class Constants {
      //Base Url
  public static BASE_URL = 'https://studentformsfunction.azurewebsites.net/api/';
 //public static BASE_URL = 'http://localhost:7071/api/';

  //Staff Methods
  public static STAFF_ADD = 'AddStaff';
  public static GET_STAFF='GetStaff';
  public static UPDATE_STAFF='UpdateStaff';
  public static DELETE_STAFF='DeleteStaff';

  //Admin Login
  public static ADMIN_LOGIN = 'GetUsers';

  //student login
  public static GET_STUDENTS = 'GetStudents';

  //File Uploads
  public static UPLOAD_FILE='UploadFile';
  public static UPLOAD_FILES='UploadFiles';

  
  public static UPDATE_STUDENTS='UpdateStudent';
  public static DELETE_STUDENTS="DeleteStudent";

  public static UPLOAD_STUDENT='UploadStudents';
  public static GET_FORMS = 'getforms';
  public static GET_PDF = 'getpdf';
  //Resend Mail
public static RESEND_EMAIL='ResendMail';
public static UPDATE_PASSWORD='UpdateStaff';

//Student
public static GET_TERMS='GetAcademicTerms';
public static UPDATE_STUDENT='UpdateStudent';

public static SAVE_REQUESTDOCUEMNT='RequestDocuments';
public static SAVE_ACCEPTDOCUEMNT='AcceptDocuments';

public static SAVE_SUBMITDOCUEMNT='SubmitDocuments';
//create Student
public static SUBMIT_FORM='SubmitForm';
//ParentSubmit
public static PARENT_SUBMIT='SubmitFormByParent';
public static SUBMIT_REJECT='RejectForm';
public static GET_TIMESHEET='GetStudentTimesheets';
}
