
<app-header></app-header>
  <div class="main-container" id="container">
    <div class="overlay"></div>
    <div class="search-overlay"></div>   
    <div class="sidebar-wrapper sidebar-theme">        
        <app-student-sidebar></app-student-sidebar>
    </div>   
    <div id="content" class="main-content">
         <div class="layout-px-spacing">
             <div class="row layout-top-spacing">      
                 <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">  
                    <div class="row">
                        <div class="col-md-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item ml-auto"><a href="dashboard/student">Dashboard</a></li>
                                <li class="breadcrumb-item active text-primary" aria-current="page"><span>Work Study Program Timesheets</span></li>
                            </ol>
                        </div> 
                      </div>
                      
                      <div class="row">
                         <div class="col-lg-12">
                            <div class="card">
                                 <div class="card-header bg-secondary">
                                      <span class="text-white"><b>Work Study Program Timesheets</b></span>                                    
                                 </div>
                                 <div class="card-body">
                                   
                                     <div class="row">
                                         <div class="table-responsive" style="padding-top:15px">
                                             <table id="tblTimesheet" class="table table-striped table-bordered" width="100%">
                                                 <thead>
                                                     <tr>                                      
                                                         <th>Student ID </th>
                                                         <th>Supervisor </th>
                                                         <th>Month  </th>
                                                         <th>Hours </th>
                                                         <th>Status</th>
                                                         <th>Actions</th>
                                                       </tr>
                                                 </thead>
                                                 <tbody>
                                                    <tr *ngFor="let data of rowDataTimesheet">
                                                        <td>{{data.StudentId}}</td>                                                     
                                                        <td>{{data.Supervisor}}</td>
                                                        <td>{{data.Month}}/{{data.Year}}</td>
                                                        <td>{{data.Hours}}</td>
                                                        <td>{{data.Status}}</td>
                                                        <td><a href="student-workstudy" class="btn btn-success" title="Enter Time">Enter Time</a></td>
                                                      </tr>
                                                 </tbody>
                                             </table>
                                         </div>
                                        
                                     </div>
                                    
                                 </div>
                            </div>
                         </div>
                         <app-footer></app-footer>    
                      </div>
                 </div>
             </div>
         </div>
    </div>
  </div>

