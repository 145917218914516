<app-header></app-header>
<div id="content" class="main-content">
  <div class="layout-px-spacing">
      <div class="row layout-top-spacing">      
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
<div class="jumbotron text-center">
    <div class="display-4">{{msg}}</div>
    <hr>
    <p class="lead">
    </p>
  </div>
</div>
</div>
</div>
</div>