<div class="form-container outer">
    <div class="form-form">
        <div class="form-form-wrap">
            <div class="form-container">
                <div class="form-content">
                    <h1 class="">Staff Login</h1>
                    <p class="">Log in to your account to continue.</p>
                    <form class="text-left" [formGroup]="loginForm" novalidate>
                        <div class="pb-4">
                            <div id="username-field" class="field-wrapper input">
                                <label for="username">USERNAME</label>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>

                                <input type="text" tabindex="0" class="form-control" formControlName="username"  id="username" placeholder="Username" required>                      
                            </div>

                            <div id="password-field" class="field-wrapper input mb-2">
                                <div class="d-flex justify-content-between">
                                    <label for="password">PASSWORD</label>
                                    <a href="#" class="forgot-pass-link">Forgot Password?</a>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-lock"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
                                <input type="password" tabindex="1" class="form-control" formControlName="password"  id="Password" placeholder="Password" required>                      
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" id="toggle-password" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
                            </div>
                            <div class="d-sm-flex justify-content-between">
                                <div class="field-wrapper">
                                    <button tabindex="2" class="btn btn-primary" (click)="login()" >Log In</button> 
                                    <!-- <button type="submit" class="btn btn-primary" id="btnLogin">Log In</button> -->
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>

    </div>
</div>


<!-- <div class="row" style="padding-top:160px;">
    <div class="col-md-4"></div>
    <div class="col-md-4 align-item-center">
        <div class="card ">
            <div class="card-header bg-primary">
                <h4 class="text-center"><b style="color:white">Admin Login</b></h4>
            </div>
            <div class="card-body">
                <form [formGroup]="loginForm" novalidate>
                    <div class="row">
                        <div class="col-md-12">  
                            <div class="form-group">
                            <label for="username">User Name</label>
                            <input type="text" class="form-control" formControlName="username"  id="username" placeholder="User Name" required>                      
                         </div>
                        </div>
                        <div class="col-md-12">  
                            <div class="form-group">
                            <label for="username">Password</label>
                            <input type="password" class="form-control" formControlName="password"  id="Password" placeholder="Password" required>                      
                         </div>
                        </div>                     
                     
                      <div class="col-md-12">
                        <div class="form-group">
                          <button class="btn btn-primary" (click)="login()" >Login</button> 
                          <a href="/forgotPassword" class="text-light f-15px">Forgot Password</a>
                        </div>
                      </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div> -->