<app-header></app-header>
  <!--  BEGIN MAIN CONTAINER  -->
  <div class="main-container" id="container">

    <div class="overlay"></div>
    <div class="search-overlay"></div>

    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">        
        <app-student-sidebar></app-student-sidebar>
    </div>
    <!--  END SIDEBAR  -->
    
    <!--  BEGIN CONTENT AREA  -->
    <div id="content" class="main-content">

    <div class="layout-px-spacing">
      <div class="row layout-top-spacing">
      
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          
          <!-- Moadal window starts-->

          <ng-template #mymodal let-modal>
            <div class="modal-header">
              <h4 class="modal-title" id="modal-basic-title">Resend Email</h4>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body">
              <form [formGroup]="emailForm">               
                  <div class="form-group">
                    <label>Email <span class="text-danger"> *</span></label>
                    <input type="email" id="email" formControlName="email" placeholder="Email" class="form-control" />
                  </div>
                  <div class="form-group">
                    <label>Confirm Email <span class="text-danger"> *</span></label>
                    <input type="email"  id="confirm_email"  formControlName="confirmemail" placeholder="Confirm Email" class="form-control" />
                  </div>
                </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-success" (click)="Resend()">Resend</button>
            </div>
          </ng-template>

          <!-- Modal Windows Ends-->

            <!-- First Grid Starts-->
            <div class="row">
              <div class="col-md-12">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item ml-auto"><a href="dashboard/student">Dashboard</a></li>
                  <li class="breadcrumb-item active text-primary" aria-current="page"><span>Student</span></li>
              </ol>
              </div>
            </div>
              
                      <div class="row">
                        <div class="col-md-12">
                          <div class="card">
                            <div class="card-header bg-secondary">
                              <span class="text-white"><b>Student Forms Progress</b></span>
                              <span class="float-right"><a href="academic-term" class="float-right" id="newform" class="btn btn-success">New Form</a></span>
                            </div>
                            <div class="card-body">
                              <div class="row">
                                <div class="col-md-6 text-left">
                                  <strong style="color: red;"> Note: Click Student ID to view Verification Form.</strong>
                                  <!-- <a href="create-staff" class="btn btn-primary"><i class="fa fa-plus"></i>&nbsp; Student Forms Progress</a> -->
                                </div>
                                <div class="col-md-6 text-right">
                                  <!-- <button class="btn btn-primary" (click)="exportExel()">Export to excel</button> -->
                                  <!-- <button class="btn btn-primary">Export to excel</button> -->
                                </div>
                              </div>
                              
                              <div class="table-responsive" style="padding-top:15px">
                                <table class="table table-striped table-bordered" width="100%">
                                  <thead>
                                    <tr>
                                        <th> Form Name</th>                                       
                                        <th>Student ID</th>
                                        <th>Student Name</th>
                                        <th>Tax Year</th>
                                        <th>Term</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let data of rowDataProgress">
                                        <td>{{data.formName}}</td>
                                        <td><a style="text-decoration: underline;" href="fafs?formid={{data.formID}}">{{data.studentID}}</a></td>
                                        <td>{{data.name}}</td>
                                        <td>{{data.taxYear}}</td>
                                        <td>{{data.term}}</td>
                                        <td>{{data.formStatus}}</td>
                                        <td >
                                          <a (click)="ViewPDF(data.pdfFilepath)" class="btn btn-success" title="print">Print</a> 
                                          &nbsp;&nbsp;
                                          <button *ngIf="data.formStatus == 'Student Submitted' && (data.dependentStatus == 'D' || data.maritalStatus == 'Married')"
                                            class="btn btn-success"  (click)="open(mymodal,data)">Resend</button> &nbsp;&nbsp;
                                            <a *ngIf="data.formStatus == 'Documents Requested'" href="requestdocument?frm=student&Rid={{data.formID}}"  class="btn btn-success" title="Attach Documents">Attach Documents</a>
                                        </td>
                                      </tr>
                                </tbody>
                                </table>
                            </div>
                            </div>
                          </div>
                        </div>
                    </div>  

                    <!-- First Grid Ends -->

                    <!-- Second Grid Starts -->
                    <br>
                    <div class="row">
                        <div class="col-md-12">
                          <div class="card">
                            <div class="card-header bg-secondary">
                              <span class="text-white"><b>Student Forms Completed</b></span>
                            </div>
                            <div class="card-body">
                              <div class="row">
                                <div class="col-md-6 text-left">
                                  
                                </div>
                                <div class="col-md-6 text-right">
                                  <!-- <button class="btn btn-primary" (click)="exportExel()">Export to excel</button> -->
                                  <!-- <button class="btn btn-primary">Export to excel</button> -->
                                </div>
                              </div>
                              
                              <div class="table-responsive" style="padding-top:15px">
                                <table class="table table-striped table-bordered" width="100%">
                                  <thead>
                                    <tr>
                                        <th> Form Name</th>                                       
                                        <th>Student ID</th>
                                        <th>Student Name</th>
                                        <th>Tax Year</th>
                                        <th>Term</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                  </thead>
                                    <tbody>
                                      <tr *ngFor="let data of rowDataCompleted">
                                        <td>{{data.formName}}</td>
                                        <td>{{data.studentID}}</td>
                                        <td>{{data.name}}</td>
                                        <td>{{data.taxYear}}</td>
                                        <td>{{data.term}}</td>
                                        <td>{{data.formStatus}}</td>
                                        <td>
                                          <a (click)="ViewPDF(data.pdfFilepath)" class="btn btn-success" title="print">Print</a>                                           
                                        </td>
                                      </tr>
                                    </tbody>
                                </table>
                            </div>
                            </div>
                          </div>
                        </div>
                    </div>  

                    <!-- Second Grid Ends -->
                
           
        </div>   
        <app-footer></app-footer>    
    </div>
  </div>

    <!--  END CONTENT AREA  -->
  </div>
</div>

<!-- END MAIN CONTAINER -->