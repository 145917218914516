import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../shared/login/login.service';
import { StaffService } from '../../shared/staff/staff.service';
import { FormControl,FormsModule,FormBuilder,FormGroup,Validators } from '@angular/forms';
import { NgbModal,ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-student-master',
  templateUrl: './student-master.component.html',
  styleUrls: ['./student-master.component.css']
})
export class StudentMasterComponent implements OnInit {
  public rowDataProgress:any=[];
  public rowDataCompleted:any=[];
  public id:any;

  public emailForm:FormGroup;
  
  closeResult: string;
  formId:string;
  constructor(private titleservice:Title,private loginService:LoginService,private staffservice:StaffService,private fb: FormBuilder,private modalService: NgbModal) {
    this.titleservice.setTitle("Student Dashboard");
    this.emailForm=fb.group({
      id:'',
      email:['',Validators.required],
      confirmemail:['',Validators.required]
    });
  
  }

  ngOnInit(): void {
    let user=this.loginService.getUser();
    if(user!=null||user!=undefined){
      if(user.role!="student"){
        window.location.href="student";
      }
    }else{
      window.location.href="student";
    }
      this.id=user.studentID;
    this.getFormsData();
  }
  ViewPDF(file)
  {
    var base64EncodedPDF="";
    this.staffservice.getPdf(file).subscribe(res=>{
      if(res.status==1){
          var sampleArr = this.staffservice.base64ToArrayBuffer(res.data);
          this.staffservice.saveByteArray(file, sampleArr);
      }
    });
  }
  getFormsData(){
    console.log(this.id);
    this.staffservice.getFormsByStudentID(this.id).subscribe(res=>{
    if(res.status==1){
    for(let i=0;i<res.data.length;i++){
    if(res.data[i].formStatus == "Counselor Accepted" || res.data[i].formStatus == "Counselor Rejected" ){
    let rej={
    formID:res.data[i].id,
    formName: res.data[i].formName,
    studentID: res.data[i].studentID,
    name: res.data[i].firstName + res.data[i].lastName,
    taxYear: res.data[i].taxYear,
    term: res.data[i].term,
    formStatus: res.data[i].formStatus,
    pdfFilepath: res.data[i].pdfFilepath
    }
    this.rowDataCompleted.push(rej);
    }else{
    let pgdt ={
    formID:res.data[i].id,
    formName: res.data[i].formName,
    studentID: res.data[i].studentID,
    name: res.data[i].firstName + res.data[i].lastName,
    taxYear: res.data[i].taxYear,
    term: res.data[i].term,
    formStatus: res.data[i].formStatus,
    pdfFilepath: res.data[i].pdfFilepath,
    dependentStatus: res.data[i].dependentStatus,
    maritalStatus: res.data[i].maritalStatus,
    }
    this.rowDataProgress.push(pgdt);
    console.log(pgdt.formID);
    if(pgdt.formID != null || pgdt.formID != "")
    {
      $("#newform").hide();
    }
    }
    
    }
    }
    });
    
    }


  open(content,itemdata) {
    this.formId = itemdata.formID;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = 'Closed with: ${result}';
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  Resend(){
    
    if(this.emailForm.value.email != this.emailForm.value.confirmemail){
        Swal.fire('Warning!','Email and confirm email should be matched','warning');
        return false;
    }

    let obj = {
      id:this.formId,
      email:this.emailForm.value.email
    }

    this.staffservice.resendMail(obj).subscribe(res=>{

      if(res.status==1){
        Swal.fire('success!',res.message,'success');

        location.reload();
      }

    });

  }

}
