<app-header></app-header>
  <!--  BEGIN MAIN CONTAINER  -->
  <div class="main-container" id="container">

    <div class="overlay"></div>
    <div class="search-overlay"></div>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">        
        <app-staff-sidebar></app-staff-sidebar>
    </div>
    <!--  END SIDEBAR  -->    
    <!--  BEGIN CONTENT AREA  -->
    <div id="content" class="main-content">
    <div class="layout-px-spacing">
      <div class="row layout-top-spacing">      
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
             <!-- Moadal window starts-->
          <ng-template #mymodal let-modal>
            <div class="modal-header">
              <h4 class="modal-title" id="modal-basic-title">Resend Email</h4>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body">
              <form [formGroup]="emailForm">               
                  <div class="form-group">
                    <label>Email <span class="text-danger"> *</span></label>
                    <input type="email" id="email" formControlName="email" placeholder="Email" class="form-control" />
                  </div>
                  <div class="form-group">
                    <label>Confirm Email <span class="text-danger"> *</span></label>
                    <input type="email"  id="confirm_email"  formControlName="confirmemail" placeholder="Confirm Email" class="form-control" />
                  </div>
                </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-success" (click)="Resend()">Resend</button>
            </div>
          </ng-template>

          <!-- Modal Windows Ends-->
<div class="row">
  <div class="col-md-12">
    <ol class="breadcrumb">
      <li class="breadcrumb-item ml-auto"><a href="dashboard/staff">Dashboard</a></li>
      <li class="breadcrumb-item active text-primary" aria-current="page"><span>Search Student</span></li>
  </ol>
  </div>
</div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="card">
                            <div class="card-header bg-secondary">
                              <span class="text-white"><b>Search Student</b></span>
                            </div>
                            <div class="card-body">
                              <div class="row">
                                <div class="col-md-12">
                                        <form [formGroup]="searchStudentModel">
                                            <div class="row">
                                                    <div class="col-md-3">
                                                        <input type="text" class="form-control" id="studentid" formControlName="studentId" placeholder="Student ID" />
                                                    </div>
                                                    <div class="col-md-3">
                                                        <input type="text" class="form-control" id="firstName" formControlName="firstName" placeholder="First Name" />
                                                    </div>
                                                    <div class="col-md-3">
                                                        <input type="text" class="form-control" id="lastName" formControlName="lastName" placeholder="LastName" />
                                                    </div>
                                                    <div class="col-md-1">
                                                      <button class="btn btn-success" (click)="getForms()">Submit</button></div>
                                              </div>
                                        </form>
                                    </div>
                                </div>
                              
                              <div class="table-responsive" style="padding-top:15px">
                                <table class="table table-striped table-bordered" width="100%">
                                  <thead>
                                    <tr>
                                        <!-- <th>Form Code</th>                                        -->
                                        <th>Form Name</th>
                                        <th>StudentID</th>
                                        <th>Student Name</th>
                                        <th>Current Term</th>
                                        <th>Tax Year</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                  </thead>
                                    <tbody>
                                      <tr *ngFor="let item of studentList">
                                        <td>{{item.formName}}</td>  
                                        <td>{{item.studentID}}</td>                                       
                                        <td>{{item.name}}</td>
                                        <td>{{item.currentTerm}}</td>   
                                         <td>{{item.taxYear}}</td>                                       
                                        <td>{{item.formStatus}}</td>
                                        <td><button class="btn btn-success" (click)="open(mymodal,item)">Resend</button></td>                                    
                                      </tr>
                                    </tbody>
                                </table>
                            </div>
                            </div>
                          </div>
                        </div>
                    </div>  
                
           
        </div>   
        <app-footer></app-footer>    
    </div>
  </div>

    <!--  END CONTENT AREA  -->
  </div>
</div>

<!-- END MAIN CONTAINER -->