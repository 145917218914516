
<app-header></app-header>
  <div class="main-container" id="container">
    <div class="overlay"></div>
    <div class="search-overlay"></div>   
    <div class="sidebar-wrapper sidebar-theme">        
        <app-student-sidebar></app-student-sidebar>
    </div>   
    <div id="content" class="main-content">
         <div class="layout-px-spacing">
             <div class="row layout-top-spacing">      
                 <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">  
                    <div class="row">
                        <div class="col-md-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item ml-auto"><a href="dashboard/student">Dashboard</a></li>
                                <li class="breadcrumb-item active text-primary" aria-current="page"><span>Monthly Timesheet View</span></li>
                            </ol>
                        </div> 
                      </div>
                      <div class="row">
                        <div class="col-lg-12">
                           <div class="card">
                                <div class="card-header bg-secondary">
                                     <span class="text-white"><b>Timesheet in progress for  June 2020</b></span>                                    
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                       <div class="col-md-12">
                                           <div class="row">                                               
                                               <div class="col-md-12 text-right">(click on the date to enter/update time)<button  class="btn btn-primary">Submit Timesheet</button>&nbsp;<button class="btn btn-success">Save as PDF</button></div>
                                           </div>                                           
                                       </div>
                                    </div>
                                    <div class="row pt-4 px-3 py-3">
                                        <div class="table-responsive">
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th class="px-2 py-2">Sunday</th>
                                                        <th class="px-2 py-2">Monday</th>
                                                        <th class="px-2 py-2">Tuesday</th>
                                                        <th class="px-2 py-2">Wednesday</th>
                                                        <th class="px-2 py-2">Thursday</th>
                                                        <th class="px-2 py-2">Friday</th>
                                                        <th class="px-2 py-2">Saturday</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                  <tr class="px-2 py-2">
                                                    <td id="td_1">
                                                        <input id="hdnID_1" value="0" type="hidden">
                                                        <input id="hdnDay_1" value="1" type="hidden">
                                                        <div style="width:100%;height:25px;text-align:right;
                                                        font-weight:bold;color:#0026ff;">1</div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            In: <span class="pull-right" style="text-align:right;" id="in-span_1"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Out: <span class="pull-right" style="text-align:right;" id="out-span_1"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Hours: <span class="pull-right" style="text-align:right;" id="hours-span_1"> </span>
                                                        </div>
                                                    </td>
                                                    <td id="td_2">
                                                        <input id="hdnID_2" value="2" type="hidden">
                                                        <input id="hdnDay_2" value="2" type="hidden">
                                                        <div style="width:100%;height:25px;text-align:right;
                                                        font-weight:bold;color:#0026ff;">2</div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            In: <span class="pull-right" style="text-align:right;" id="in-span_2"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Out: <span class="pull-right" style="text-align:right;" id="out-span_2"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Hours: <span class="pull-right" style="text-align:right;" id="hours-span_2"> </span>
                                                        </div>
                                                    </td>
                                                    <td id="td_3">
                                                        <input id="hdnID_2" value="2" type="hidden">
                                                        <input id="hdnDay_2" value="2" type="hidden">
                                                        <div style="width:100%;height:25px;text-align:right;
                                                        font-weight:bold;color:#0026ff;">3</div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            In: <span class="pull-right" style="text-align:right;" id="in-span_2"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Out: <span class="pull-right" style="text-align:right;" id="out-span_2"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Hours: <span class="pull-right" style="text-align:right;" id="hours-span_2"> </span>
                                                        </div>
                                                    </td>
                                                    <td id="td_4">
                                                        <input id="hdnID_2" value="2" type="hidden">
                                                        <input id="hdnDay_2" value="2" type="hidden">
                                                        <div style="width:100%;height:25px;text-align:right;
                                                        font-weight:bold;color:#0026ff;">4</div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            In: <span class="pull-right" style="text-align:right;" id="in-span_2"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Out: <span class="pull-right" style="text-align:right;" id="out-span_2"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Hours: <span class="pull-right" style="text-align:right;" id="hours-span_2"> </span>
                                                        </div>
                                                    </td>
                                                    <td id="td_5">
                                                        <input id="hdnID_2" value="2" type="hidden">
                                                        <input id="hdnDay_2" value="2" type="hidden">
                                                        <div style="width:100%;height:25px;text-align:right;
                                                        font-weight:bold;color:#0026ff;">5</div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            In: <span class="pull-right" style="text-align:right;" id="in-span_2"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Out: <span class="pull-right" style="text-align:right;" id="out-span_2"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Hours: <span class="pull-right" style="text-align:right;" id="hours-span_2"> </span>
                                                        </div>
                                                    </td>
                                                    <td id="td_6">
                                                        <input id="hdnID_2" value="2" type="hidden">
                                                        <input id="hdnDay_2" value="2" type="hidden">
                                                        <div style="width:100%;height:25px;text-align:right;
                                                        font-weight:bold;color:#0026ff;">6</div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            In: <span class="pull-right" style="text-align:right;" id="in-span_2"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Out: <span class="pull-right" style="text-align:right;" id="out-span_2"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Hours: <span class="pull-right" style="text-align:right;" id="hours-span_2"> </span>
                                                        </div>
                                                    </td>
                                                    <td id="td_7">
                                                        <input id="hdnID_2" value="2" type="hidden">
                                                        <input id="hdnDay_2" value="2" type="hidden">
                                                        <div style="width:100%;height:25px;text-align:right;
                                                        font-weight:bold;color:#0026ff;">7</div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            In: <span class="pull-right" style="text-align:right;" id="in-span_2"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Out: <span class="pull-right" style="text-align:right;" id="out-span_2"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Hours: <span class="pull-right" style="text-align:right;" id="hours-span_2"> </span>
                                                        </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td_8">
                                                        <input id="hdnID_8" value="8" type="hidden">
                                                        <input id="hdnDay_8" value="8" type="hidden">
                                                        <div style="width:100%;height:25px;text-align:right;
                                                        font-weight:bold;color:#0026ff;">8</div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            In: <span class="pull-right" style="text-align:right;" id="in-span_8"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Out: <span class="pull-right" style="text-align:right;" id="out-span_8"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Hours: <span class="pull-right" style="text-align:right;" id="hours-span_8"> </span>
                                                        </div>
                                                    </td>
                                                    <td id="td_9">
                                                        <input id="hdnID_9" value="9" type="hidden">
                                                        <input id="hdnDay_9" value="9" type="hidden">
                                                        <div style="width:100%;height:25px;text-align:right;
                                                        font-weight:bold;color:#0026ff;">9</div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            In: <span class="pull-right" style="text-align:right;" id="in-span_9"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Out: <span class="pull-right" style="text-align:right;" id="out-span_9"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Hours: <span class="pull-right" style="text-align:right;" id="hours-span_9"> </span>
                                                        </div>
                                                    </td>
                                                    <td id="td_10">
                                                        <input id="hdnID_10" value="10" type="hidden">
                                                        <input id="hdnDay_10" value="10" type="hidden">
                                                        <div style="width:100%;height:25px;text-align:right;
                                                        font-weight:bold;color:#0026ff;">10</div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            In: <span class="pull-right" style="text-align:right;" id="in-span_10"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Out: <span class="pull-right" style="text-align:right;" id="out-span_10"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Hours: <span class="pull-right" style="text-align:right;" id="hours-span_10"> </span>
                                                        </div>
                                                    </td>
                                                    <td id="td_11">
                                                        <input id="hdnID_11" value="11" type="hidden">
                                                        <input id="hdnDay_11" value="11" type="hidden">
                                                        <div style="width:100%;height:25px;text-align:right;
                                                        font-weight:bold;color:#0026ff;">11</div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            In: <span class="pull-right" style="text-align:right;" id="in-span_11"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Out: <span class="pull-right" style="text-align:right;" id="out-span_11"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Hours: <span class="pull-right" style="text-align:right;" id="hours-span_11"> </span>
                                                        </div>
                                                    </td>
                                                    <td id="td_12">
                                                        <input id="hdnID_12" value="12" type="hidden">
                                                        <input id="hdnDay_12" value="12" type="hidden">
                                                        <div style="width:100%;height:25px;text-align:right;
                                                        font-weight:bold;color:#0026ff;">12</div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            In: <span class="pull-right" style="text-align:right;" id="in-span_12"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Out: <span class="pull-right" style="text-align:right;" id="out-span_12"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Hours: <span class="pull-right" style="text-align:right;" id="hours-span_12"> </span>
                                                        </div>
                                                    </td>
                                                    <td id="td_13">
                                                        <input id="hdnID_13" value="13" type="hidden">
                                                        <input id="hdnDay_13" value="13" type="hidden">
                                                        <div style="width:100%;height:25px;text-align:right;
                                                        font-weight:bold;color:#0026ff;">13</div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            In: <span class="pull-right" style="text-align:right;" id="in-span_13"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Out: <span class="pull-right" style="text-align:right;" id="out-span_13"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Hours: <span class="pull-right" style="text-align:right;" id="hours-span_13"> </span>
                                                        </div>
                                                    </td>
                                                    <td id="td_14">
                                                        <input id="hdnID_14" value="14" type="hidden">
                                                        <input id="hdnDay_14" value="14" type="hidden">
                                                        <div style="width:100%;height:25px;text-align:right;
                                                        font-weight:bold;color:#0026ff;">14</div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            In: <span class="pull-right" style="text-align:right;" id="in-span_14"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Out: <span class="pull-right" style="text-align:right;" id="out-span_14"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Hours: <span class="pull-right" style="text-align:right;" id="hours-span_14"> </span>
                                                        </div>
                                                    </td> 
                                                  </tr>
                                                  <tr>
                                                    <td id="td_15">
                                                        <input id="hdnID_15" value="15" type="hidden">
                                                        <input id="hdnDay_15" value="15" type="hidden">
                                                        <div style="width:100%;height:25px;text-align:right;
                                                        font-weight:bold;color:#0026ff;">15</div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            In: <span class="pull-right" style="text-align:right;" id="in-span_15"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Out: <span class="pull-right" style="text-align:right;" id="out-span_15"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Hours: <span class="pull-right" style="text-align:right;" id="hours-span_15"> </span>
                                                        </div>
                                                    </td>
                                                    <td id="td_16">
                                                        <input id="hdnID_16" value="16" type="hidden">
                                                        <input id="hdnDay_16" value="16" type="hidden">
                                                        <div style="width:100%;height:25px;text-align:right;
                                                        font-weight:bold;color:#0026ff;">16</div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            In: <span class="pull-right" style="text-align:right;" id="in-span_16"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Out: <span class="pull-right" style="text-align:right;" id="out-span_16"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Hours: <span class="pull-right" style="text-align:right;" id="hours-span_16"> </span>
                                                        </div>
                                                    </td>
                                                    <td id="td_17">
                                                        <input id="hdnID_17" value="17" type="hidden">
                                                        <input id="hdnDay_17" value="17" type="hidden">
                                                        <div style="width:100%;height:25px;text-align:right;
                                                        font-weight:bold;color:#0026ff;">17</div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            In: <span class="pull-right" style="text-align:right;" id="in-span_17"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Out: <span class="pull-right" style="text-align:right;" id="out-span_17"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Hours: <span class="pull-right" style="text-align:right;" id="hours-span_17"> </span>
                                                        </div>
                                                    </td>
                                                    <td id="td_18">
                                                        <input id="hdnID_18" value="18" type="hidden">
                                                        <input id="hdnDay_18" value="18" type="hidden">
                                                        <div style="width:100%;height:25px;text-align:right;
                                                        font-weight:bold;color:#0026ff;">18</div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            In: <span class="pull-right" style="text-align:right;" id="in-span_18"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Out: <span class="pull-right" style="text-align:right;" id="out-span_18"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Hours: <span class="pull-right" style="text-align:right;" id="hours-span_18"> </span>
                                                        </div>
                                                    </td>
                                                    <td id="td_19">
                                                        <input id="hdnID_19" value="19" type="hidden">
                                                        <input id="hdnDay_19" value="19" type="hidden">
                                                        <div style="width:100%;height:25px;text-align:right;
                                                        font-weight:bold;color:#0026ff;">19</div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            In: <span class="pull-right" style="text-align:right;" id="in-span_19"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Out: <span class="pull-right" style="text-align:right;" id="out-span_19"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Hours: <span class="pull-right" style="text-align:right;" id="hours-span_19"> </span>
                                                        </div>
                                                    </td>
                                                    <td id="td_20">
                                                        <input id="hdnID_20" value="20" type="hidden">
                                                        <input id="hdnDay_20" value="20" type="hidden">
                                                        <div style="width:100%;height:25px;text-align:right;
                                                        font-weight:bold;color:#0026ff;">20</div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            In: <span class="pull-right" style="text-align:right;" id="in-span_20"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Out: <span class="pull-right" style="text-align:right;" id="out-span_20"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Hours: <span class="pull-right" style="text-align:right;" id="hours-span_20"> </span>
                                                        </div>
                                                    </td>
                                                    <td id="td_21">
                                                        <input id="hdnID_21" value="21" type="hidden">
                                                        <input id="hdnDay_21" value="21" type="hidden">
                                                        <div style="width:100%;height:25px;text-align:right;
                                                        font-weight:bold;color:#0026ff;">21</div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            In: <span class="pull-right" style="text-align:right;" id="in-span_21"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Out: <span class="pull-right" style="text-align:right;" id="out-span_21"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Hours: <span class="pull-right" style="text-align:right;" id="hours-span_21"> </span>
                                                        </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td_22">
                                                        <input id="hdnID_22" value="22" type="hidden">
                                                        <input id="hdnDay_22" value="22" type="hidden">
                                                        <div style="width:100%;height:25px;text-align:right;
                                                        font-weight:bold;color:#0026ff;">22</div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            In: <span class="pull-right" style="text-align:right;" id="in-span_22"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Out: <span class="pull-right" style="text-align:right;" id="out-span_22"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Hours: <span class="pull-right" style="text-align:right;" id="hours-span_22"> </span>
                                                        </div>
                                                    </td>
                                                    <td id="td_23">
                                                        <input id="hdnID_23" value="23" type="hidden">
                                                        <input id="hdnDay_23" value="23" type="hidden">
                                                        <div style="width:100%;height:25px;text-align:right;
                                                        font-weight:bold;color:#0026ff;">2</div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            In: <span class="pull-right" style="text-align:right;" id="in-span_23"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Out: <span class="pull-right" style="text-align:right;" id="out-span_23"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Hours: <span class="pull-right" style="text-align:right;" id="hours-span_23"> </span>
                                                        </div>
                                                    </td>
                                                    <td id="td_24">
                                                        <input id="hdnID_24" value="24" type="hidden">
                                                        <input id="hdnDay_24" value="24" type="hidden">
                                                        <div style="width:100%;height:25px;text-align:right;
                                                        font-weight:bold;color:#0026ff;">24</div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            In: <span class="pull-right" style="text-align:right;" id="in-span_24"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Out: <span class="pull-right" style="text-align:right;" id="out-span_24"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Hours: <span class="pull-right" style="text-align:right;" id="hours-span_24"> </span>
                                                        </div>
                                                    </td>
                                                    <td id="td_25">
                                                        <input id="hdnID_25" value="25" type="hidden">
                                                        <input id="hdnDay_25" value="25" type="hidden">
                                                        <div style="width:100%;height:25px;text-align:right;
                                                        font-weight:bold;color:#0026ff;">25</div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            In: <span class="pull-right" style="text-align:right;" id="in-span_25"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Out: <span class="pull-right" style="text-align:right;" id="out-span_25"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Hours: <span class="pull-right" style="text-align:right;" id="hours-span_25"> </span>
                                                        </div>
                                                    </td>
                                                    <td id="td_26">
                                                        <input id="hdnID_26" value="26" type="hidden">
                                                        <input id="hdnDay_26" value="26" type="hidden">
                                                        <div style="width:100%;height:25px;text-align:right;
                                                        font-weight:bold;color:#0026ff;">26</div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            In: <span class="pull-right" style="text-align:right;" id="in-span_26"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Out: <span class="pull-right" style="text-align:right;" id="out-span_26"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Hours: <span class="pull-right" style="text-align:right;" id="hours-span_26"> </span>
                                                        </div>
                                                    </td>
                                                    <td id="td_27">
                                                        <input id="hdnID_27" value="27" type="hidden">
                                                        <input id="hdnDay_27" value="27" type="hidden">
                                                        <div style="width:100%;height:25px;text-align:right;
                                                        font-weight:bold;color:#0026ff;">27</div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            In: <span class="pull-right" style="text-align:right;" id="in-span_27"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Out: <span class="pull-right" style="text-align:right;" id="out-span_27"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Hours: <span class="pull-right" style="text-align:right;" id="hours-span_27"> </span>
                                                        </div>
                                                    </td>
                                                    <td id="td_28">
                                                        <input id="hdnID_28" value="28" type="hidden">
                                                        <input id="hdnDay_28" value="28" type="hidden">
                                                        <div style="width:100%;height:25px;text-align:right;
                                                        font-weight:bold;color:#0026ff;">28</div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            In: <span class="pull-right" style="text-align:right;" id="in-span_28"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Out: <span class="pull-right" style="text-align:right;" id="out-span_28"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Hours: <span class="pull-right" style="text-align:right;" id="hours-span_28"> </span>
                                                        </div>
                                                    </td>
                                                    
                                                  </tr>
                                                  <tr>
                                                    <td id="td_29">
                                                        <input id="hdnID_29" value="29" type="hidden">
                                                        <input id="hdnDay_29" value="29" type="hidden">
                                                        <div style="width:100%;height:25px;text-align:right;
                                                        font-weight:bold;color:#0026ff;">29</div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            In: <span class="pull-right" style="text-align:right;" id="in-span_29"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Out: <span class="pull-right" style="text-align:right;" id="out-span_29"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Hours: <span class="pull-right" style="text-align:right;" id="hours-span_29"> </span>
                                                        </div>
                                                    </td>
                                                    <td id="td_30">
                                                        <input id="hdnID_30" value="30" type="hidden">
                                                        <input id="hdnDay_30" value="30" type="hidden">
                                                        <div style="width:100%;height:25px;text-align:right;
                                                        font-weight:bold;color:#0026ff;">30</div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            In: <span class="pull-right" style="text-align:right;" id="in-span_30"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Out: <span class="pull-right" style="text-align:right;" id="out-span_30"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Hours: <span class="pull-right" style="text-align:right;" id="hours-span_30"> </span>
                                                        </div>
                                                    </td>
                                                    <td id="td_0">
                                                        <input id="hdnID_0" value="0" type="hidden">
                                                        <input id="hdnDay_0" value="0" type="hidden">
                                                        <div style="width:100%;height:25px;text-align:right;
                                                        font-weight:bold;color:#0026ff;">0</div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            In: <span class="pull-right" style="text-align:right;" id="in-span_0"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Out: <span class="pull-right" style="text-align:right;" id="out-span_0"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Hours: <span class="pull-right" style="text-align:right;" id="hours-span_0"> </span>
                                                        </div>
                                                    </td>
                                                    <td id="td_41">
                                                        <input id="hdnID_41" value="41" type="hidden">
                                                        <input id="hdnDay_41" value="41" type="hidden">
                                                        <div style="width:100%;height:25px;text-align:right;
                                                        font-weight:bold;color:#0026ff;">0</div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            In: <span class="pull-right" style="text-align:right;" id="in-span_41"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Out: <span class="pull-right" style="text-align:right;" id="out-span_41"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Hours: <span class="pull-right" style="text-align:right;" id="hours-span_41"> </span>
                                                        </div>
                                                    </td>
                                                    <td id="td_42">
                                                        <input id="hdnID_42" value="42" type="hidden">
                                                        <input id="hdnDay_42" value="42" type="hidden">
                                                        <div style="width:100%;height:25px;text-align:right;
                                                        font-weight:bold;color:#0026ff;">0</div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            In: <span class="pull-right" style="text-align:right;" id="in-span_42"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Out: <span class="pull-right" style="text-align:right;" id="out-span_42"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Hours: <span class="pull-right" style="text-align:right;" id="hours-span_42"> </span>
                                                        </div>
                                                    </td>
                                                    <td id="td_43">
                                                        <input id="hdnID_43" value="43" type="hidden">
                                                        <input id="hdnDay_43" value="43" type="hidden">
                                                        <div style="width:100%;height:25px;text-align:right;
                                                        font-weight:bold;color:#0026ff;">0</div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            In: <span class="pull-right" style="text-align:right;" id="in-span_43"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Out: <span class="pull-right" style="text-align:right;" id="out-span_43"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Hours: <span class="pull-right" style="text-align:right;" id="hours-span_43"> </span>
                                                        </div>
                                                    </td>
                                                    <td id="td_44">
                                                        <input id="hdnID_44" value="44" type="hidden">
                                                        <input id="hdnDay_44" value="44" type="hidden">
                                                        <div style="width:100%;height:25px;text-align:right;
                                                        font-weight:bold;color:#0026ff;">0</div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            In: <span class="pull-right" style="text-align:right;" id="in-span_44"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Out: <span class="pull-right" style="text-align:right;" id="out-span_44"> </span>
                                                        </div>
                                                        <div style="width:100%;height:25px;text-align:left;">
                                                            Hours: <span class="pull-right" style="text-align:right;" id="hours-span_44"> </span>
                                                        </div>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                           </div>
                        </div>
                     </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
                      
