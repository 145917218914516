import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css']
})
export class InfoComponent implements OnInit {

  public msg:string;
  constructor(private titleservice:Title) { 

    this.titleservice.setTitle("Info");
    
  }

  ngOnInit(): void {debugger;
    $("#container").removeClass("main-container");
    $(".main-content").removeAttr("id");
    $("#username").hide();$("#userProfileDropdown").hide();
    let infomsg = localStorage.getItem('info');
      this.msg = infomsg;
    localStorage.setItem('info', "");
  }

}
