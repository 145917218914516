<app-header></app-header>
<!--  BEGIN MAIN CONTAINER  -->
<div class="main-container" id="container">
    <div class="overlay"></div>
    <div class="search-overlay"></div>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">
        <app-staff-sidebar></app-staff-sidebar>
    </div>
    <!--  END SIDEBAR  -->
    <!--  BEGIN CONTENT AREA  -->
    <div id="content" class="main-content">
        <div class="layout-px-spacing">
            <div class="row layout-top-spacing">      
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="row">
                <div class="col-md-12">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item ml-auto"><a href="dashboard/staff">Dashboard</a></li>
                        <li class="breadcrumb-item active text-primary" aria-current="page"><span>Reset Password</span></li>
                    </ol>
                </div>
            </div>
            <div class="card">
                <div class="card-header bg-secondary"><b class="text-white">Reset Password</b></div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <form [formGroup]="StaffModel">
                                <!-- <div class="form-group" hidden>
                                    <label>ID</label>
                                    <input class="form-control" type="text" id="id" formControlName="id"
                                        placeholder="ID" readonly>
                                </div> -->

                                <div class="row">
                                    <div class="col-md-6"> 
                                        <div class="input-group mb-4">
                                            <input type="text" class="form-control" id="Oldpassword" 
                                              placeholder="Old Password" formControlName="Oldpassword" [ngClass]="{ 'is-invalid': submitted && f.Oldpassword.errors }" />
                                           
                                            <div class="input-group-append">
                                                <span class="input-group-text" id="basic-addon2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-key"><path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path></svg></span>
                                            </div>
                                            <div *ngIf="submitted && f.Oldpassword.errors" class="invalid-feedback">
                                                <div *ngIf="f.Oldpassword.errors.required">Old Password is required</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6"> 
                                        <div class="input-group mb-4">
                                            <input type="text" class="form-control" id="password" placeholder="Password" 
                                            formControlName="password"  [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                                         
                                            <div class="input-group-append">
                                                <span class="input-group-text" id="basic-addon2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-lock"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg></span>
                                            </div>
                                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                <div *ngIf="f.password.errors.required">Password is required</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6"> 
                                        <div class="input-group mb-4">
                                            <input type="text" class="form-control"  id="confirm_password" placeholder="Confirm Password" formControlName="confirm_password" 
                                             [ngClass]="{ 'is-invalid': submitted && f.confirm_password.errors }" />                                            
                                            <div class="input-group-append">
                                                <span class="input-group-text" id="basic-addon2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-lock"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg></span>
                                            </div>
                                            <div *ngIf="submitted && f.confirm_password.errors" class="invalid-feedback">
                                                <div *ngIf="f.confirm_password.errors.required">Confirm Password is required</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6"> 
                                        <div class="input-group mb-4">
                                            <button type="submit" class="btn btn-success mr-1" [disabled]="disable"  (click)="savePassword()">Update Password</button>&nbsp;
                                        </div>
                                    </div>
                                </div>



                              
                            </form>

                            <!-- AG GRID BINDING-->


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
        <app-footer></app-footer>
    </div>
    <!--  END CONTENT AREA  -->

</div>

<!-- END MAIN CONTAINER -->