import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Routes } from '@angular/router';
import { FormBuilder,FormControl, FormGroup,FormArray, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { StaffService } from '../../shared/staff/staff.service';
import { LoginService } from '../../shared/login/login.service';
import { Route } from '@angular/compiler/src/core';
import { isGeneratedFile } from '@angular/compiler/src/aot/util';
import {Title} from "@angular/platform-browser";
declare var $;
@Component({
  selector: 'app-requestdocument',
  templateUrl: './requestdocument.component.html',
  styleUrls: ['./requestdocument.component.css']
})
export class RequestdocumentComponent implements OnInit {
  public disable=false;
  public flag = false;
  public RequestType:string='';
  public ReqDocumentModel:FormGroup;
  public user: any;  
  public frm:any;
  public formId:string='';
  public Employeeid:string='';
  public Staffname:string='';
  // public Studenttax:string=''; 
  // public Studentw21:string='';
  // public Parenttax:string='';
  // public Parentw21:string='';
  public stax:string='';
  public ptax:string='';
  public sw2:string='';
  public pw2:string='';
  public IRSTaxRetStudentfilepath:string='';
  public StudentW2filepath:string='';
  public IRSTaxRetParentfilepath:string='';
  public ParentW2filepath:string='';
  public link:any;
  public studentTax:any;
  public parenttax:any;
  public studentW2:any;
  public parentW2:any;
  public Reason:any;
  public Term:any;
  public TaxYear:any;
  public StudentId:any;
  studentfile: FormGroup;  
  parentfile: FormGroup; 
  studentW2file:FormGroup;
  parentW2file:FormGroup;
  public isparent:boolean;
  public ishidestudentsidebar:boolean;
  public ishidestaffsidebar:boolean;

 //Reason=new FormControl();
  
  onSTax($event){   
    if($event.target.checked==true){
      this.stax="1";
    }
    else{
      this.stax="";
    }
  }
  onSW2($event){   
    
    if($event.target.checked==true){
      this.sw2="1";
    }
    else
    {
      this.sw2="";
    }
  }
  onPTax($event){   

    if($event.target.checked==true){
      this.ptax="1";
    }
    else
    {
      this.ptax="";
    }
  }
  onPW2($event){    

    if($event.target.checked==true){
      this.pw2="1";
    }
    else
    {
      this.pw2="";
    }
  }

 

  constructor(private titleservice:Title,private fb: FormBuilder, private httpClient: HttpClient,
    private staffService: StaffService,private route:ActivatedRoute,private loginService: LoginService,public routing:Router) { 
      this.titleservice.setTitle("Request Documents");
      this.ReqDocumentModel = fb.group({  
        RequestType:'ADD',          
        id:'',
        employeeid:'',        
        Reason:['',Validators.required],
        IRSTaxRetStudentfilepath:'',
        StudentW2filepath:'',
        IRSTaxRetParentfilepath:'',
        ParentW2filepath:'',
        studentdocument:'',
        studentW2document:'',
        parentdocument:'',
        parentW2document:'',
      });

    }
    uploadstudentfile(event) {    
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        if(!this.validateFile(file.name)){
          Swal.fire("Warning!","Please select jpg, jpeg, png, bmp, pdf files only.","warning");
          var $s=$("#studentfile");
          $s.val("");
          return false;
        }
        this.studentfile.get('studentdocument').setValue(file);
      }
    } 
    uploadparentfile(event) {    
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        if(!this.validateFile(file.name)){
          Swal.fire("Warning!","Please select jpg, jpeg, png, bmp, pdf files only.","warning");
          var $p=$("#parentfile");
          $p.val("");
          return false;
        }
        this.parentfile.get('parentdocument').setValue(file);
      }
    } 
    uploadstudentW2file(event) {    
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        if(!this.validateFile(file.name)){
          Swal.fire("Warning!","Please select jpg, jpeg, png, bmp, pdf files only.","warning");
          var $sw2=$("#studentfileW2");
          $sw2.val("");
          return false;
        }
        this.studentW2file.get('studentW2document').setValue(file);
      }
    } 
    uploadparentW2file(event) {    
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        if(!this.validateFile(file.name)){
          Swal.fire("Warning!","Please select jpg, jpeg, png, bmp, pdf files only.","warning");
          var $pw2=$("#parentfileW2");
          $pw2.val("");
          return false;
        }
        this.parentW2file.get('parentW2document').setValue(file);
      }
    } 
  ngOnInit(): void {
    $("#br1").hide();$("#br2").hide();
    this.isparent=false;
    this.studentfile = this.fb.group({
      studentdocument: ['']
    });
    this.parentfile = this.fb.group({
      parentdocument: ['']
    });
    this.studentW2file = this.fb.group({
      studentW2document: ['']
    });
    this.parentW2file = this.fb.group({
      parentW2document: ['']
    });

    this.user = this.loginService.getUser();
  
    if(this.user!=null){
      
      if(this.user.role=="admin"){
        this.routing.navigate(['staff']);
      }

      if(this.user.role=="student")
      { $("#btncancel").show();
        this.ishidestudentsidebar = true;
        this.ishidestaffsidebar = false;
        this.link=true; 
      }else if(this.user.role=="parent"){
       
        this.ishidestudentsidebar=false;
        this.ishidestaffsidebar=false;
        this.link=true;
      }
      else
      {
        this.ishidestaffsidebar=true;
        this.link=false; 
       }
      if(this.user.role=="staff")
      { this.link=false; $("#btncancel").hide();
        this.Employeeid = this.user.employeeId;      
      }
    }else{
      this.routing.navigate(['staff']);
    }
   
      this.formId = this.route.snapshot.queryParams["Rid"];     
      this.frm = this.route.snapshot.queryParams["frm"];   
      if(this.user!=null){  
        this.Staffname=this.user.firstName+' '+this.user.lastName;
      }

    //this.Id=this.user.id;

    if(this.formId!=null && this.formId!=""){          
      this.staffService.getFormsById(this.formId).subscribe(res=>{        
       if(res.status==1){

        if(res.data[0].dependentStatus == "I" && res.data[0].maritalStatus == "Single"){
$("#dvParent").hide();
        }
        //staff check

        if(this.frm=="staff"){
          $("#btncancel").hide();
          if(res.data[0].formStatus == "Counselor Accepted"||res.data[0].formStatus == "Counselor Rejected"||res.data[0].formStatus == "Documents Requested"){
            localStorage.setItem('info', "Access denied.");  
            window.location.href="info";
           }
        }

         if(this.frm=="student")
         {$("#btncancel").show();
           $("#title").html("Please upload requested documents.");
           this.EditStudentFill(res);
         }else if(this.frm=="parent"){
           if(res.data[0].formStatus == "Documents Requested")
           {
             this.ishidestudentsidebar=false;
             this.ishidestaffsidebar=false;
              $("#container").removeClass("main-container");
              $(".main-content").removeAttr("id");
              $("#br1").show();$("#br2").show();
              $("#title").html("Please upload requested documents.");
              $("#username").hide();$("#userProfileDropdown").hide();
              $("#btncancel").hide();
              this.link=true;
              this.EditStudentFill(res);   
           }
           else if(res.data[0].formStatus == "Documents Requested")
           {
             localStorage.setItem('info', "Form already submitted.");
             window.location.href="info";
           }
        }
         else
         {
           this.EditFill(res);
         }
       }
       else
           {
             if(this.frm=="parent")
             {
             localStorage.setItem('info', "Access denied.");
             window.location.href="info";
             }
           }
      });
   }
    console.log("Link = "+this.link);
  }

  EditStudentFill(rowData){
    this.studentTax=rowData.data[0].isStudentTaxRequested==1?true:false;  
    if(this.studentTax==1)
    {
      $("#Studenttax").prop("disabled",true);
    }   
    else
    {
      $("#Studenttax").prop("disabled",true);
      $("#divStudent").hide();
    }
    this.parenttax = rowData.data[0].isParentTaxRequested==1?true:false;
    if(this.parenttax==1)
    {
      $("#parenttax").prop("disabled",true);
    }
    else
    { $("#parenttax").prop("disabled",true);
      $("#divParent").hide();
    }
    this.studentW2 = rowData.data[0].isStudentW2Requested==1?true:false;
    if(this.studentW2==1)
    {
      $("#studentw21").prop("disabled",true);
    }
    else
    {
      $("#studentw21").prop("disabled",true);
      $("#divStudentW2").hide();
    }
    this.parentW2 = rowData.data[0].isParentW2Requested==1?true:false;
    if(this.parentW2==1){
      $("#parentw21").prop("disabled",true);
    }
    else
    { $("#parentw21").prop("disabled",true);
      $("#divParentW2").hide();
    }
    this.Term=rowData.data[0].term;
    this.TaxYear=rowData.data[0].taxYear;
    this.StudentId=rowData.data[0].studentID;
    this.Reason=rowData.data[0].rejectReason;
    if(this.Reason!=null)
    {
      $("#Reason").prop("disabled",true);
    }
   
      this.ReqDocumentModel=this.fb.group({
       RequestType:"Edit",      
       IRSTaxRetStudentfilepath:[rowData.data[0].irsTaxRetStudentfilepath],     
       StudentW2filepath:[rowData.data[0].studentW2filepath],
       IRSTaxRetParentfilepath:[rowData.data[0].irsTaxRetParentfilepath],    
       ParentW2filepath:[rowData.data[0].parentW2filepath],
       Reason:[rowData.data[0].rejectReason],

     });
  }

  EditFill(rowData){
    this.ReqDocumentModel=this.fb.group({        
      RequestType:"ADD",
      IRSTaxRetStudentfilepath:[rowData.data[0].irsTaxRetStudentfilepath],     
      StudentW2filepath:[rowData.data[0].studentW2filepath],
      IRSTaxRetParentfilepath:[rowData.data[0].irsTaxRetParentfilepath],    
      ParentW2filepath:[rowData.data[0].parentW2filepath],
      Reason:[rowData.data[0].reason],     
     
    });
   //this.disable=false;
  }

  ValidateForm(): boolean {
    return true;
  }

  get f() { return this.ReqDocumentModel.controls; }


//Save  Request Documents
  saveDocument(){
    
    if(this.stax=="" && this.ptax=="" && this.sw2=="" && this.pw2=="")
    {     
       Swal.fire("Warning","Please select atleast one checkbox for request for documents.","warning");
       return;

    }
    if(this.ReqDocumentModel.value.Reason==null){    
       Swal.fire("Warning","Please enter reason for request documents","warning");
       return;      
    }

    if(this.ReqDocumentModel.value.RequestType=="ADD"){      
       let obj={        
         employeeid:this.Employeeid,
         formid:this.formId,
         staffname:this.Staffname,
         studenttax:this.stax,
         parenttax:this.ptax,
         studentw2:this.sw2,
         parentw2:this.pw2,
         reason:this.ReqDocumentModel.value.Reason,
       }
       this.staffService.SaveRequestDocuemnt(obj).subscribe(res=>{    
          if(res.status==1){
            window.location.href="dashboard/staff";              
          }else {
            Swal.fire({
              icon: 'warning',
              title: 'Warning',
              text: 'Error while request documents.'
            });
          }

       });
    }
  }

//Save  Accept Documents
  saveAcceptDocument(){    
   
    if(this.ReqDocumentModel.value.RequestType=="ADD"){
      
      let obj={        
        employeeid:this.Employeeid,
        formid:this.formId,
        staffname:this.Staffname,       
      }
      this.staffService.SaveAcceptDocuemnt(obj).subscribe(res=>{  
         if(res.status==1){
          window.location.href="dashboard/staff";
         }else {
           Swal.fire({
             icon: 'warning',
             title: 'Warning',
             text: 'Error while accept documents.'
           });
         }

      });
    }
  }
  
  submitAcceptDocument(){
  
    if(this.studentTax==true)
    {
      
      if(this.studentfile.get('studentdocument').value=="")
      {
       Swal.fire("Warning","Please upload Student tax  file.","warning");
       return;
      }
    }
    if(this.parenttax==true)
    {
      
      if(this.parentfile.get('parentdocument').value=="")
      {
       Swal.fire("Warning","Please upload Parent tax  file.","warning");
       return;
      }
    }
    if(this.studentW2==true){
      if(this.studentW2file.get('studentW2document').value=="")
      {
       Swal.fire("Warning","Please upload Student W2 file.","warning");
       return;
      }
    }

    if(this.parentW2==true){
      if(this.parentW2file.get('parentW2document').value=="")
      {
       Swal.fire("Warning","Please upload Parent W2 file.","warning");
       return;
      }
    }


    if(this.ReqDocumentModel.value.RequestType=="Edit"){
      const formData = new FormData();
      formData.append('formid', this.formId);
      formData.append('term', this.Term);
      formData.append('taxyear', this.TaxYear);
      formData.append('studentid', this.StudentId);
      formData.append('studenttax', this.studentfile.get('studentdocument').value);
      formData.append('parenttax', this.parentfile.get('parentdocument').value);
      formData.append('studentw2', this.studentW2file.get('studentW2document').value);
      formData.append('parentw2', this.parentW2file.get('parentW2document').value);

      // let objs={
      //   formid:this.formId,
      //   term:this.Term,
      //   taxyear:this.TaxYear,
      //   studentid:this.StudentId,
      //   studenttax:this.studentfile.get('studentdocument').value,
      //   parenttax:this.parentfile.get('parentdocument').value,
      //   studentw2:this.studentW2file.get('studentW2document').value,
      //   parentw2:this.parentW2file.get('parentW2document').value,
      // }
      this.staffService.submitDocument(formData).subscribe(res=>{
        
        if(res.status==1)
        {
          if(this.frm=="student"){
            window.location.href="dashboard/student";
          }else if(this.frm=="parent"){
            localStorage.setItem('info', "Documents submitted successfully.");
            window.location.href="info";
          }
        }
        else{
          Swal.fire({
            icon: 'warning',
            title: 'Warning',
            text: 'Error while upload documents.'
          });
        }
      })
    }
  }

  onclear(){
    this.ReqDocumentModel = this.fb.group({  
      RequestType:'ADD',          
      id:'',
      employeeid:'', 
      Studenttax:'',
      Studentw21:'',
      Parenttax:'',
      Parentw21:'',
      Reason:['',Validators.required],
      IRSTaxRetStudentfilepath:'',
      StudentW2filepath:'',
      IRSTaxRetParentfilepath:'',
      ParentW2filepath:'',
     
    });
  }
  ViewPDF(id)
  {
   var file = $("#" + id).val()
    var base64EncodedPDF="";
    this.staffService.getPdf(file).subscribe(res=>{
      if(res.status==1){
          var sampleArr = this.staffService.base64ToArrayBuffer(res.data);
          this.staffService.saveByteArray(file, sampleArr);
      }
    });
  }

  //File validation example
  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'png') {
        return true;
    }else if(ext.toLowerCase() =='jpeg'){
      return true;
    }else if(ext.toLowerCase() =='jpg'){
      return true;
    }else if(ext.toLowerCase() =='pdf'){
      return true;
    }else if(ext.toLowerCase() =='dmp'){
      return true;
    }else {
        return false;
    }
  }

}
