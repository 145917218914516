import { Component, OnInit } from '@angular/core';
import { StudentService } from '../../shared/student/student.service';
import { FormControl,FormsModule,FormBuilder,FormGroup,Validators } from '@angular/forms';
import { LoginService } from '../../shared/login/login.service';
import Swal from 'sweetalert2';
import { HttpRequest } from '@angular/common/http';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-academic-term',
  templateUrl: './academic-term.component.html',
  styleUrls: ['./academic-term.component.css']
})
export class AcademicTermComponent implements OnInit {

  public terms:any=[];
  public termvalue:any;
  public termgroup:FormGroup;
  public user:any;

  constructor(private titleservice:Title,private studentservice:StudentService,private loginService:LoginService,public fb: FormBuilder) {
    
    this.titleservice.setTitle("Academic Term");
    
    this.termgroup = fb.group({
      term:['',Validators.required]
    });
   }

  
  ngOnInit(): void {
    this.getTerms();
    this.user = this.loginService.getUser();
    if(this.user!=null||this.user!=undefined){
      if(this.user.role!="student"){
        window.location.href="student";
      }
    }else{
      window.location.href="student";
    }
  }

  getTerms(){

    this.studentservice.getAcademicTerms().subscribe(res=>{
      console.log(res);
      if(res.status == 1){

        for(let i=0;i<res.data.length;i++){
          let obj={
            term:res.data[i].term,
            termName:res.data[i].termName
          }
          this.terms.push(obj);
        }

      }
    });

  }

  submit(){
    debugger;
    //console.log(this.termgroup.value);
    if(this.termgroup.value.term == "")
    {
      Swal.fire("Warning!","Please select term.","warning");
      return false;
    }
    let obj={
      studentid : this.user.studentID,
      term : this.termgroup.value.term,
      taxyear : this.user.taxYear,
      from:'accademicterm'
    }
    console.log(obj);

    this.studentservice.updateStudent(obj).subscribe(res=>{

      console.log(res);

      if(res.status==1){
        Swal.fire("Success!","Student updated successfully","success");
        //window.location.href="students-form";
        window.location.href="fafs";
      }

    });
  }

}
