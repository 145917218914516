<app-header></app-header>
  <!--  BEGIN MAIN CONTAINER  -->
  <div class="main-container" id="container">
    <div class="overlay"></div>
    <div class="search-overlay"></div>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">        
        <app-staff-sidebar></app-staff-sidebar>
    </div>
    <!--  END SIDEBAR  -->    
    <!--  BEGIN CONTENT AREA  -->
    <div id="content" class="main-content">
    <div class="layout-px-spacing">
      <div class="row layout-top-spacing">      
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div  class="row">
            <div  class="col-md-12">
              <ol class="breadcrumb">
                <li class="breadcrumb-item ml-auto"><a href="dashboard/staff">Dashboard</a></li>
                <li class="breadcrumb-item active text-primary" aria-current="page"><span>Student List</span></li>
            </ol>
            </div>
          </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="card">
                            <div class="card-header bg-secondary">
                              <span class="text-white"><b>Student List</b></span>
                            </div>
                            <div class="card-body">
                               <div class="row">                   
                                  <div class="table-responsive">
                                    <table id="tblSList" class="table table-striped table-bordered" width="100%">
                                      <thead>
                                        <tr>
                                          <th>Student ID</th>                                       
                                          <th>Student Name</th>
                                          <th>Email Address</th>
                                       </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let item of studentList">
                                          <td>{{item.studentID}}</td>                                       
                                          <td>{{item.name}}</td>
                                          <td>{{item.email}}</td>                                       
                                        </tr>
                                      </tbody>
                                   </table>
                                 </div>                           
                              </div>                          
                          </div>
                        </div>
                    </div>  
                </div>   
        <app-footer></app-footer>    
    </div>
  </div>

    <!--  END CONTENT AREA  -->
  </div>
</div>

<!-- END MAIN CONTAINER -->