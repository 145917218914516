import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { Constants } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  authToken: any;
  user: any;
  role: any;
  constructor(private http: HttpClient) { }

  Baseurl(){
    return Constants.BASE_URL;
  }

  adminlogin(userData): any {
    return this.http.get<any>(this.Baseurl()+Constants.ADMIN_LOGIN+"?username="+userData.username+"&password="+userData.password);
  }

  stafflogin(userData): any {
    return this.http.get<any>(this.Baseurl()+Constants.GET_STAFF+"?username="+userData.username+"&password="+userData.password);
  }

  studentlogin(userData): any {
    return this.http.get<any>(this.Baseurl()+Constants.GET_STUDENTS+"?username="+userData.username+"&password="+userData.password);
  }


  logout(): any {
    this.authToken = null;
    this.user = null;
    localStorage.clear();
  }

  storeUserData(token: any, user: any): void {
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));

    this.authToken = token;
    this.user = user;
  }

  loadToken() {
    this.authToken = localStorage.getItem('token');
  }
  
  getToken(): string {
    return localStorage.getItem('token');
  }
  getUser(): any {
    return JSON.parse(localStorage.getItem('user'));
  }


}
