import { Injectable } from '@angular/core';
import { Constants } from '../constants';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StudentService {

  constructor(private http:HttpClient) { }

  Baseurl(){
   return Constants.BASE_URL;
  }

  getAcademicTerms():Observable<any>{
    return this.http.get<any>(this.Baseurl()+Constants.GET_TERMS);
  }

  updateStudent(obj):Observable<any>{
    return this.http.get<any>(this.Baseurl()+Constants.UPDATE_STUDENT+"?studentid="+obj.studentid+"&term="+obj.term+"&taxyear="+obj.taxyear+"&from="+obj.from);
    
  }

  getFormBystudentID(studentid):Observable<any>{
    return this.http.get<any>(this.Baseurl()+Constants.GET_FORMS+"?studentid="+studentid);
  }
  submitForm(formData):any{
    // const uploadReq = new HttpRequest('POST',Constants.BASE_URL + Constants.SUBMIT_FORM, formData);
    // return this.http.request(uploadReq);
    
    return this.http.post<any>(this.Baseurl()+Constants.SUBMIT_FORM,formData);
    }
    Parentsubmit(formData):any{
      return this.http.post<any>(this.Baseurl()+Constants.PARENT_SUBMIT,formData);
      }
      getTimesheet(studentid):Observable<any>{
        return this.http.get<any>(this.Baseurl()+Constants.GET_TIMESHEET+"?studentid="+studentid);
        }
}
