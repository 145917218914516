import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../shared/login/login.service';
import { StaffService } from '../../shared/staff/staff.service';
import { FormControl,FormsModule,FormBuilder,FormGroup,Validators } from '@angular/forms';
import { NgbModal,ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-search-student',
  templateUrl: './search-student.component.html',
  styleUrls: ['./search-student.component.css']
})
export class SearchStudentComponent implements OnInit {

  public user:any;
  public studentList:any=[];
  public userid:any;
  public nameseries:any;
  public searchStudentModel:FormGroup;
  public emailForm:FormGroup;
  
  closeResult: string;
  formId:string;
 

  constructor(private titleservice:Title,private loginService:LoginService,private staffservice:StaffService,private fb: FormBuilder,private modalService: NgbModal) {

    this.titleservice.setTitle("Search Students");

    this.emailForm=fb.group({
      id:'',
      email:['',Validators.required],
      confirmemail:['',Validators.required]
    });

    this.searchStudentModel = fb.group({  
      studentId:[''],
      firstName:[''],
      lastName:[''],
    });

   }

  

  ngOnInit(): void {
    this.user = this.loginService.getUser();
    if(this.user!=null||this.user!=undefined){
      if(this.user.role!="staff"){
        window.location.href="staff";
      }
    }else{
      window.location.href="staff";
    }
    this.userid = this.user.employeeId;
    this.nameseries = this.user.nameSeries;

    //this.getForms();
  }

  getForms(){
    
    this.studentList=[];
    if(this.searchStudentModel.value.studentId=="" 
       && this.searchStudentModel.value.firstName=="" 
       && this.searchStudentModel.value.lastName==""){
        Swal.fire("Warning","Enter any one of the field.","warning");
        return;
     }
debugger;
    let obj={
      employeeid : this.userid,
      nameseries : this.nameseries,
      studentid :this.searchStudentModel.value.studentId,
      firstname :this.searchStudentModel.value.firstName,  
      lastname:this.searchStudentModel.value.lastName
    }

    this.staffservice.getStudentForms(obj).subscribe(res=>{
      if(res.status == 1){
        for(let i=0;i<res.data.length;i++){
          let dt={
            formID:res.data[i].id,
            formName : res.data[i].formName,
            studentID : res.data[i].studentID,
            name : res.data[i].firstName+" "+res.data[i].lastName,
            currentTerm : res.data[i].currentTerm,
            taxYear : res.data[i].taxYear,
            formStatus: res.data[i].formStatus
          }
          this.studentList.push(dt);
        }

      }
    });

  }

  open(content,itemdata) {
   // alert(itemdata.formID);
    this.formId = itemdata.formID;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = 'Closed with: ${result}';
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  Resend(){
    
    if(this.emailForm.value.email != this.emailForm.value.confirmemail){
        Swal.fire('Warning!','Email and confirm email should be matched','warning');
        return false;
    }

    let obj = {
      id:this.formId,
      email:this.emailForm.value.email
    }

    this.staffservice.resendMail(obj).subscribe(res=>{

      if(res.status==1){
        Swal.fire('success!',res.message,'success');
        location.reload();
      }

    });

  }

}
