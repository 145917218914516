<app-header></app-header>

<div class="main-container" id="container">
    <!-- <div class="overlay"></div>
    <div class="search-overlay"></div> -->

        <!--  BEGIN SIDEBAR  -->
        <div *ngIf="from=='student'" class="sidebar-wrapper sidebar-theme">        
            <app-student-sidebar></app-student-sidebar>
        </div>
    
        <div *ngIf="from=='staff'" class="sidebar-wrapper sidebar-theme">        
            <app-staff-sidebar></app-staff-sidebar>
        </div>
        <!--  END SIDEBAR  -->    
    <!--  BEGIN CONTENT AREA  -->
   
<div id="content" class="main-content">
    <div class="layout-px-spacing">
      <div class="row layout-top-spacing">      
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="row">
                <div class="col-md-12">
                   <ol class="breadcrumb">
                    <li class="breadcrumb-item ml-auto" *ngIf="from=='staff'"><a href="dashboard/staff">Dashboard</a></li>
                     <li class="breadcrumb-item ml-auto" *ngIf="from=='student'"><a href="dashboard/student">Dashboard</a></li>
                     <li class="breadcrumb-item active text-primary" aria-current="page"><span>Verification Form</span></li>
                   </ol> 
                </div>
            </div>
             <div class="row">
               <div class="col-md-12">
               <div class="card">
                 <div class="card-header bg-secondary">
                        <h6 class="text-white">Verification Form</h6>
                  </div>
                  <div class="card-body">
                    <div class="layout-spacing">  
                        <div class="animated-underline-content">
                             <ul class="nav nav-tabs  mb-3" id="animateLine" role="tablist">
                                <li class="nav-item">
                                   <a class="nav-link active" id="animated-underline-home-tab" data-toggle="tab" style="cursor: pointer;" (click)="active('animated-underline-home','animated-underline-profile','animated-underline-contact','animated-underline-contact1')" role="tab" aria-controls="animated-underline-home" aria-selected="true"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>&nbsp;STUDENT INFORMATION</a>
                                </li>
                                <li class="nav-item">
                                   <a class="nav-link" id="animated-underline-profile-tab" data-toggle="tab" style="cursor: pointer;" (click)="active('animated-underline-profile','animated-underline-home','animated-underline-contact','animated-underline-contact1')" role="tab" aria-controls="animated-underline-profile" aria-selected="false"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>&nbsp;FAMILY INFORMATION</a>
                                </li>
                                <li class="nav-item">
                                  <a class="nav-link" id="animated-underline-contact-tab" data-toggle="tab" style="cursor: pointer;" (click)="active('animated-underline-contact','animated-underline-profile','animated-underline-home','animated-underline-contact1')" role="tab" aria-controls="animated-underline-contact" aria-selected="false"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>&nbsp;FINANCIAL INFORMATION</a>
                                </li>
                                <li class="nav-item">
                                  <a class="nav-link" id="animated-underline-contact1-tab" data-toggle="tab" style="cursor: pointer;" (click)="active('animated-underline-contact1','animated-underline-profile','animated-underline-home','animated-underline-contact')" role="tab" aria-controls="animated-underline-contact" aria-selected="false"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-settings"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>&nbsp;VERF. OF UNTAXED INCOME</a>
                                </li>
                             </ul>
            
                         
                        <div class="tab-content" id="animateLineContent-4">
            
                            <div class="tab-pane fade show active pl-4" id="animated-underline-home" role="tabpanel" aria-labelledby="animated-underline-home-tab">
                             
                                <form [formGroup]="studentForm">
                                    <div class="form-row">
                                        <div class="col-md-3 mb-4">
                                            <label for="validationDefault01">First name</label>
                                            <input readonly type="text" class="form-control" id="firstName" placeholder="First name" formControlName="firstName" required>
                                        </div>
                                        <div class="col-md-3 mb-4">
                                            <label for="validationDefault02">Last name</label>
                                            <input readonly type="text" class="form-control" placeholder="Last name" formControlName="lastName" required>
                                        </div>
                                        <div class="col-md-3 mb-4">
                                            <label for="validationDefault03">Middle name</label>
                                            <input readonly type="text" class="form-control"  placeholder="Middle name" formControlName="middleName" required>
                                        </div>
                                        <div class="col-md-3 mb-4">
                                            <label for="validationDefault04">Student ID</label>
                                            <input type="text" class="form-control" placeholder="Student ID" formControlName="studentID" required readonly>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="col-md-3 mb-4">
                                            <label for="validationDefault05">Social Security Number</label>
                                            <input type="text" class="form-control"  placeholder="Social Security Number" formControlName="ssNumber" required>
                                        </div>
                                        <div class="col-md-3 mb-4">
                                            <label for="validationDefault06" >Gender</label>
                                            <input type="text" id="gender" formControlName="gender" class="form-control" readonly>
                                        </div>
                                        <div class="col-md-3 mb-4">
                                            <label for="dob">Date of Birth</label>
                                            <input readonly type="text" placeholder="Choose Date" class="form-control" bsDatepicker  [bsConfig]="datePickerConfig" formControlName="dob"/>
                                         </div>
                                        
                                        
                                        <div class="col-md-3 mb-4">
                                            <label for="validationDefault06">Marital Status</label>
                                            <input type="text" id="martialstatus" formControlName="martialstatus" class="form-control" readonly>
                                        </div>
                                    </div>
            
                                    <div class="form-row">
                                        <div class="col-md-3 mb-4">
                                            <label for="validationDefault08">Personal Email</label>
                                            <input readonly type="text" class="form-control" placeholder="Personal Email" formControlName="personalEmailAddress" required>
                                        </div>
                                        <div class="col-md-3 mb-4">
                                            <label for="validationDefault04">School Email</label>
                                            <input readonly type="text" class="form-control" placeholder="School Email" formControlName="schoolEmailAddress"  required>
                                        </div>
                                        <div class="col-md-6 mb-4">
                                            <label for="validationDefault05">Address</label>
                                            <input type="text" class="form-control"  placeholder="Address" formControlName="address" required>
                                        </div>
            
                                    </div>
                                    <div class="form-row">
                                        <div class="col-md-3 mb-4">
                                            <label for="validationDefault08">Phone Number</label>
                                            <input type="tel" class="form-control" placeholder="Phone Number" formControlName="phoneNumber" required>
                                        </div>
                                        <div class="col-md-3 mb-4">
                                            <label for="validationDefault08">Contact Number</label>
                                            <input type="tel" class="form-control" placeholder="Contact Number" formControlName="contactNumber" required>
                                        </div>
                                        <div class="col-md-3 mb-4">
                                            <label for="validationDefault08">City</label>
                                            <input type="text" class="form-control" placeholder="City" formControlName="city" required>
                                        </div>
                                        <div class="col-md-3 mb-4">
                                            <label for="validationDefault04">State</label>
                                            <input type="text" class="form-control" placeholder="State" formControlName="state" required>
                                        </div>
                                    </div>
            
                                    <div class="form-row">
                                        <div class="col-md-3 mb-4">
                                            <label for="validationDefault05">Zip</label>
                                            <input type="text" class="form-control"  placeholder="Zip" formControlName="zipcode" required>
                                        </div>
                                        <div class="col-md-3 mb-4">
                                            <label for="validationDefault05">Current Term</label>
                                            <input readonly type="text" class="form-control"  placeholder="Current Term" formControlName="currentTerm" required>
                                        </div>
                                        <div class="col-md-3 mb-4">
                                            <label for="validationDefault05">Classification</label>
                                            <input type="text" class="form-control"  placeholder="Classification" formControlName="gradeName" required>
                                        </div>
                                    </div>
            
                                    <div class="row">
                                        <div class="col-md-12 text-right">
                                            <a (click)="next('animated-underline-profile','animated-underline-home')" class=" btn btn-secondary btn-sm" id="btnVWSheet">Next</a>
                                           
                                        </div>
                                    </div>
            
                                </form>
                            </div>            
            
                            <div class="tab-pane fade pl-4" id="animated-underline-profile" role="tabpanel" aria-labelledby="animated-underline-profile-tab">
                                <form>
                                    <div class="form-row">
                                        <div class="col-md-6">
                                            <div class="row pl-3">
                                                <div class="custom-control custom-checkbox checkbox-info">
                                                    <input type="radio" class="custom-control-input" id="Dependent" [disabled]="true" [checked]="isDependent" (change)="dependentChange($event)">
                                                    <label class="custom-control-label" for="Dependent">Dependent Student</label>
                                                </div>
                                            </div>
                                            <div class="row pl-5 pt-1 pr-1">
                                                &nbsp;  <a class="text-align-left">
                                                    If you are a dependent student list,<br />1.Yourself,<br />2.Your Parent(s),include step parent(s), even if you do not live with your &nbsp;&nbsp; parents.<br />
                                                    3.Other children your parents will provide more than half of their support &nbsp;&nbsp; from July 1,2019 thru June 30 ,2020
                                                </a>
            
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row pl-3">
                                                <div class="custom-control custom-checkbox checkbox-info">
                                                    <input type="radio" class="custom-control-input" id="InDependent" [disabled]="true" [checked]="isIndividual" (change)="individualChange($event)" >
                                                    <label class="custom-control-label" for="InDependent">Independent Student</label>
                                                </div>
                                            </div>
                                            <div class="row pl-5 pt-1 pr-1">
                                                &nbsp;  <a class="text-align-left text-justify">
                                                    If you are a Independent student list,<br />1.Yourself,<br />2.Student's spouse(if married)<br />
                                                    3.Your dependent children that you will provide more than half of their support&nbsp;from July 1,2019 thru June 30,2020.
                                                </a>
            
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row pl-5 pt-4 text-justify pr-3">
                                        <strong>
                                            Write the names of all the family members.Also write in the name of the colllege of any family member  who will be enrolled  at
                                            least half-time in a degree or certification program between July 1,2019 thru June 30,2020.
                                        </strong>
                                    </div>
                                    <div class="row pl-5 pt-4 pr-2">
                                        <form *ngIf="ishidestudentrelatives" [formGroup]="studenttable">
                                             <div class="row">                                            
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label>Name</label><strong style="color:red">*</strong>
                                                        <input type="text" class="form-control" id="name" formControlName="name">
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <label>Age</label><strong style="color:red">*</strong>
                                                    <input type="text" class="form-control" id="age" formControlName="age">
                                                </div>
                                                <div class="col-md-3">
                                                    <label>Relationship</label><strong style="color:red">*</strong>
                                                    <input type="text" class="form-control" id="relationship" formControlName="relationship">
                                                </div>
                                                <div class="col-md-3">
                                                    <label>College</label><strong style="color:red">*</strong>
                                                    <input type="text" class="form-control" id="college" formControlName="college">
                                                </div>
											 </div>
											 <div class="row">
                                                <div class="col-md-12 pb-2 text-right">
                                                    <button *ngIf="padd" class="btn btn-primary" (click)="AddStudentData()">Add</button>
                                                    <button *ngIf="pupdate" class="btn btn-primary" (click)="updaterelationship()">Update</button>
                                                </div>
                                             </div>
                                          
                                        </form>
                                        <br>
                                        <div class="table-responsive">
                                            <table class="table table-bordered" width="100%">
                                                <thead>
                                                    <tr>
                                                        <th>Full Name</th>
                                                        <th>Age</th>
                                                        <th>Relationship</th>
                                                        <th>College</th>
                                                        <th *ngIf="ishidestudentrelatives">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of familyInformation; let i=index">
                                                        <td>{{item.name}}</td>
                                                        <td>{{item.age}}</td>
                                                        <td>{{item.relationship}}</td>
                                                        <td>{{item.college}}</td>
                                                        <td *ngIf="ishidestudentrelatives"><button class="btn btn-primary" (click)="relationshipEdit(i)" >Edit</button> &nbsp; <button class="btn btn-danger" (click)="relationshipDelete(i)">Delete</button></td>
                                                    </tr>
                                                  
                                                </tbody>
                                            </table>
                                        </div>
            
                                    </div>
            
                                    <div class="row pr-2">
                                        <div class="col-md-12 text-sm-right pl-5">
                                            <a (click)="next('animated-underline-home','animated-underline-profile')" class="btn btn-secondary btn-sm">Previous </a>&nbsp;&nbsp;
                                            <a (click)="next('animated-underline-contact','animated-underline-profile')" 
                                            class="btn btn-secondary btn-sm">Next</a>
                                        </div>
                                      
                                    </div>
                                </form>
                            </div>            
            
                            <div class="tab-pane fade" id="animated-underline-contact" role="tabpanel" aria-labelledby="animated-underline-contact-tab">
                                <form>
                                    <div class="form-row">
                                        <div class="row pl-5 pr-3 text-justify">
                                            <b>Please check the appropriate box.(dependent student have to complete student and parent section)</b>
                                        </div>
                                    </div>
            
                                    <div class="row pl-4 pt-1">
                                        <div class="col-md-6">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="text-center">
                                                        <h6><b>Student</b></h6>
                                                    </div>
            
                                                    <div class="n-chk ">
                                                        <label class="new-control new-checkbox new-checkbox-text checkbox-info">
                                                            <input type="checkbox" class="new-control-input" [checked]="isstudentattached" (change)="studentattached($event)">
                                                            <span class="new-control-indicator"></span>Attached is a copy of my 2016 IRS Tax Return Transcript
                                                        </label>
                                                    </div>
													  <div class="pt-2">
                                                        <div> 
                                                            <input type="file" id="sfile" accept="application/pdf,image/jpeg,image/png,image/bmp,image/jpg"
                                                             accept="application/pdf,image/jpeg,image/png,image/bmp,image/jpg" (change)="studenttaxfile($event)">
                                                           
                                                        </div>
                                                    </div>
                                                    <div class="label pt-2">
                                                        <b>Previous File:</b>
                                                        <a style="cursor: pointer;" (click)="ViewPDF(irststudenttaxfile)" >{{irststudenttaxfile}}</a>
                                                    </div>
                                                    <div class="n-chk pt-2">
                                                        <label class="new-control new-checkbox new-checkbox-text checkbox-info">
                                                            <input type="checkbox" class="new-control-input" [checked]="isstudentirsdt" (change)="studentirstdt($event)">
                                                            <span class="new-control-indicator"></span>Student has used the IRS Data Retrieval tool on FAFSA on the web.
                                                        </label>
                                                    </div>
                                                    <div class="n-chk pt-2">
                                                        <label class="new-control new-checkbox new-checkbox-text checkbox-info">
                                                            <input type="checkbox" class="new-control-input" [checked]="isstudentfiled" (change)="studentnofiling($event)">
                                                            <span class="new-control-indicator"></span><b>No,</b>I will not file and are not required to.
                                                        </label>
                                                    </div>
                                                    <div class="label pt-2">
                                                        <b>List your 2016 income below and provide W-2 if applicable.</b>
                                                    </div>
                                                    
                                                  
                                                    <div class="pt-2">
                                                        <div>
                                                            <input  type="file" id="swfile" accept="application/pdf,image/jpeg,image/png,image/bmp,image/jpg" (change)="studentw2($event)">
                                                          
                                                        </div>
                                                    </div>
                                                    <div class="label pt-4">
                                                        <b>Previous File:</b>
                                                        <a style="cursor: pointer;" (click)="ViewPDF(irststudentw2)" >{{irststudentw2}}</a>
                                                    </div>
                                                </div>
                                            </div>
            
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row">
                                                <div id="dvParent" class="col-md-12">
                                                    <div class="text-center">
                                                        <h6><b>Parent</b></h6>
                                                    </div>
            
                                                    <div class="n-chk">
                                                        <label class="new-control new-checkbox new-checkbox-text checkbox-info">
                                                            <input type="checkbox" class="new-control-input" [checked]="isparentattached" (change)="parentattached($event)">
                                                            <span class="new-control-indicator"></span>Attached is a copy of my parent(s)2016 IRS Tax Return Transcript
                                                        </label>
                                                    </div>
													   <div class="pt-2">
                                                        <div>														  
                                                            <input  type="file" id="pfile" accept="application/pdf,image/jpeg,image/png,image/bmp,image/jpg" (change)="parenttaxfile($event)">                                                          
                                                        </div>
                                                    </div>
                                                    <div class="label pt-2 pl-2">
                                                        <b>Previous File:</b>
                                                        <a style="cursor: pointer;" (click)="ViewPDF(irstparenttaxfile)" >{{irstparenttaxfile}}</a>
                                                    </div>
                                                    <div class="n-chk pt-2">
                                                        <label class="new-control new-checkbox new-checkbox-text checkbox-info">
                                                            <input type="checkbox" class="new-control-input" [checked]="isparentirsdt" (change)="parentirstdt($event)">
                                                            <span class="new-control-indicator"></span>Parent(s) used the IRS Data Retrieval tool on FAFSA on the web.
                                                        </label>
                                                    </div>
                                                    <div class="n-chk pt-2">
                                                        <label class="new-control new-checkbox new-checkbox-text checkbox-info">
                                                            <input type="checkbox" class="new-control-input" [checked]="isparentfiled" (change)="parentnofiling($event)">
                                                            <span class="new-control-indicator"></span><b>No,</b>My Parents will not file and are not required to.
                                                        </label>
                                                    </div>
                                                    <div class="label pt-2">
                                                        <b>List your 2016 income below and provide W-2 if applicable.</b>
                                                    </div>
                                                 
                                                    <div class="pt-2">
                                                        <div>														 
                                                            <input  type="file" id="pwfile" accept="application/pdf,image/jpeg,image/png,image/bmp,image/jpg" (change)="parentw2($event)">                                                          
                                                        </div>
                                                    </div>
                                                   
                                                    <div class="label pt-4">
                                                        <b>Previous File:</b>
                                                        <a style="cursor: pointer;" (click)="ViewPDF(irstparentw2)" >{{irstparentw2}}</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row pl-4 pt-2 pr-3">
                                        <b class="text-justify">
                                            &nbsp;&nbsp;Complete this section if the student and/or  parent did not file and is not required
                                            to file a 2016 income tax return with the IRS.Please list below &nbsp;&nbsp;and provide a W-2 if applicable.
                                        </b>
                                    </div>
                                    <div class="row pl-5 pt-4 pr-2">
                                        <form *ngIf="ishidestudentIncome" [formGroup]="employeeInfoform">
                                            <div class="row">
                                               
                                               
                                               <div class="col-md-3">
                                                   <div class="form-group">
                                                       <label>Name of the Employers</label><strong style="color:red">*</strong>
                                                       <input type="text" class="form-control" id="name" formControlName="employee">
                                                   </div>
                                               </div>
                                               <div class="col-md-3">
                                                   <label>Student annual amt.earned</label>
                                                   <input type="text" class="form-control" id="age" formControlName="studentannual">
                                               </div>
                                               <div class="col-md-3">
                                                   <label>Spouse's annual amt.earned</label>
                                                   <input type="text" class="form-control" id="relationship" formControlName="spouseannual">
                                               </div>
                                               <div class="col-md-3">
                                                   <label>Parent(s) annual amt.earned</label>
                                                   <input type="text" class="form-control" id="college" formControlName="parentannual">
                                               </div>
                                              
                                                </div>
												 <div class="row">
                                               <div class="col-md-12 text-right pb-3 pt-3">
                                                      <button *ngIf="eadd" class="btn btn-primary" (click)="AddemployeeInfo()">Add</button>
                                                   <button *ngIf="eupdate" class="btn btn-primary" (click)="employeeUpdate()">Update</button>
                                               </div>
                                           
                                           </div>
                                       </form>
                                       <br>
                                        <div class="table-responsive">
                                            <table class="table table-bordered" width="100%">
                                                <thead>
                                                    <tr>
                                                        <th width="20%">Name of the Employer</th>
                                                        <th width="20%">Student annual amount earned  or received  in 2016</th>
                                                        <th width="20%">Spouse's  annual amount earned or received in 2016</th>
                                                        <th width="20%">Parent(s)  annual amount earned or received in 2016</th>
														<th *ngIf="ishidestudentIncome" width="20%">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of employeeInfoData; let j=index" >
                                                        <td width="20%">{{item.employee}}</td>
                                                        <td width="20%">{{item.studentannual}}</td>
                                                        <td width="20%">{{item.spouseannual}}</td>
                                                        <td width="20%">{{item.parentannual}}</td>
														<td *ngIf="ishidestudentIncome" width="20%"><button class="btn btn-primary" (click)="employeeEdit(j)">Edit</button> &nbsp; <button class="btn btn-danger" (click)="employeeDelete(j)">Delete</button></td>
                                                    </tr>
            
                                                </tbody>
                                            </table>
                                        </div>
            
                                    </div>
            
                                    <div class="row pr-2 pt-2">
                                        <div class="col-md-12 text-sm-right pl-5">
                                            <a (click)="next('animated-underline-profile','animated-underline-contact')" 
                                            class="btn btn-secondary btn-sm pr-4">Previous</a>&nbsp;&nbsp;
                                            <a (click)="next('animated-underline-contact1','animated-underline-contact')"
                                             class="btn btn-secondary btn-sm">Next</a>
                                        </div>
            
                                    </div>
                                </form>
            
            
                            </div>

                            <div class="tab-pane fade" id="animated-underline-contact1" role="tabpanel" aria-labelledby="animated-underline-contact-tab">
                                <form [formGroup]="verifyForm">
                                    <div class="row pl-3 pr-3">
                                        <div class="col-lg-12 text-justify">
                                            <b>&nbsp;&nbsp;Complete this section for you and your parent(s).Please indicate "zero" or "N/A" if not application.DO NOT LEAVE ANY SECTION BLANK</b>
                                        </div>
                                    </div>
                
                                    <div class="row pl-3 pr-3 pt-3">
                                        <div class="col-lg-12 text-justify">
                                            <b>
                                                &nbsp;&nbsp;If you and your parent(s) did not have any source of income from work in 2016,
                                                Please describe how you and your familt met its living expenses &nbsp;&nbsp;in 2016.
                                            </b>
                                        </div>
                                    </div>
                
                                    <div class="row pl-3 pr-3 pt-3">
                                        <div class="col-lg-12">
                                            <textarea class="form-control" cols="4" id="verifUnTaxedInc" formControlName="verifUnTaxedInc" rows="4"></textarea>
                                        </div>
                                    </div>
                
                                    <div class="row pl-3 pr-3 pt-3 text-justify">
                                        <b class="pl-3 pr-3">
                                            SIGN BELOW!By signing this worksheet,I(we) certify that all the information reported on this worksheet is complete and correct.
                                            If dependent student,at least one parent must sign.
                                        </b>
                                    </div>
                
                                    <div class="row pl-3 pr-3 pt-3 text-justify">
                                        <b class="pl-3 pr-3 text-warning">
                                            Warning:If you purposely give false or misleading information on the worksheet,you may be fined,sentenced to jail,or both.
                                        </b>
                                    </div>
                
                                    <div class="row pl-3 pt-3">
                                        <div class="col-md-3">
                                        <div class="card" style="height: 240px;">
                                            <div class="card-header bg-info">Student Signature.</div>
                                            <div class="card-body">
                                                
                                                <div class="row pl-2 pt-2 pr-2">                                                
                                                        <input type="text" class="form-control" placeholder="Student Signature" id="studentSignature" formControlName="studentSignature" />                                                
                                                </div>
                                                <div class="row pl-2 pt-2 pr-2">
                                                    <input type="text" placeholder="Choose Date" class="form-control" bsDatepicker  [bsConfig]="datePickerConfig" id="studentSignatureDate" formControlName="studentSignatureDate"/>
                                                                                                   
                                                </div>
                                            </div>
                
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="card" style="height: 240px;">
                                            <div class="card-header bg-info">Parent/Spouse Signature.</div>
                                            <div class="card-body">
                                                <div *ngIf="from=='parent';else student" class="row pl-2 pt-2 pr-2">                                                    
                                                        <input type="text" class="form-control" placeholder="Parent/Spouse Signature" formControlName="parentSpouseSignature"/>                                                
                        
                                                </div>
                                                <ng-template #student>
                                                    <div class="row pl-2 pt-2 pr-2">                                                    
                                                        <input type="text" class="form-control" placeholder="Parent/Spouse Signature" formControlName="parentSpouseSignature" readonly/>                                                                        
                                                    </div>
                                                </ng-template>

                                                <div *ngIf="from=='parent';else parentdate" class="row pl-2 pt-2 pr-2">
                                                    <input type="text" placeholder="Choose Date" class="form-control" bsDatepicker  [bsConfig]="datePickerConfig" formControlName="parentSpouseSignatureDate"/>                         
                                                </div>

                                                <ng-template #parentdate>
                                                    <div class="row pl-2 pt-2 pr-2">
                                                        <input type="text" placeholder="Choose Date" class="form-control" bsDatepicker  [bsConfig]="datePickerConfig" formControlName="parentSpouseSignatureDate" readonly/>                         
                                                    </div>
                                                </ng-template>

                                                <p class="text-danger">(PARENT SIGNATURE REQUIRED FOR Dependent Students Only)</p>
                                            </div>
                
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="card" style="height: 240px;">
                                            <div class="card-header bg-info">Parent/Spouse Email</div>
                                            <div class="card-body">
                                                <div class="row pl-2 pt-2 pr-2"><input type="email" id="parentorSpouseEmail" [disabled]="isparentemaildisable" class="form-control" placeholder="Parent/Spouse Email" formControlName="parentorSpouseEmail"/></div>
                                            </div>
                
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="card" style="height: 240px;">
                                            <div class="card-header bg-info">Form Submitted Date</div>
                                            <div class="card-body">
                                                <div class="row pl-2 pt-2 pr-2">
                                                    <input type="text" placeholder="Choose Date" class="form-control" bsDatepicker  [bsConfig]="datePickerConfig" id="signatureDate" formControlName="signatureDate" /> 
                                                    
                                                </div> 
                                            </div>
                
                                        </div>
                                    </div>
                                    </div>
                
                                    <ng-template #template>  
                                        <div class="modal-header">  
                                          <h4 class="modal-title pull-left">Reject Form</h4>  
                                          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">  
                                            <span aria-hidden="true">x</span>  
                                          </button>  
                                        </div>  
                                        <div class="modal-body">  
                                        <div [formGroup]="RejectForm">
                                            <div class="form-group">                                              
                                                <textarea id="reason" formControlName="reason" placeholder="Reason" class="form-control"></textarea>
                                              </div>
                                            </div>
                                        </div>  
                                        <div class="modal-footer">  
                                            <button type="button" class="btn btn-primary" (click)="saveReason()">Submit</button> &nbsp;
                                          <button type="button" class="btn btn-default" (click)="modalRef.hide()">Close</button>  
                                        </div>  
                                      </ng-template>  
                
                                
                
                                    <div class="row pl-3 pr-3 pt-4">
                                        <div *ngIf="ishideSubmit" class="col-md-12 text-right">
                                            <a (click)="next('animated-underline-contact','animated-underline-contact1')" 
                                            class="btn btn-secondary btn-sm pr-4">Previous</a>&nbsp;
                                            <button class="btn btn-secondary btn-sm" (click)="SaveForm()">Submit</button>&nbsp;
                                            <a href="dashboard/student" class="btn btn-danger btn-sm">Cancel</a>
                                        </div>
                                        <div *ngIf="ishidestaffbutton" class="col-md-12 text-right">
                                            <button class="btn btn-info btn-sm" (click)="saveRequestDoc()">Request Documents</button>&nbsp;
                                            <button class="btn btn-info btn-sm" (click)="saveAcceptDocument()">Accept</button>&nbsp;
                                            <button type="button" class="btn btn-danger btn-sm" (click)="openModalWithClass(template)">Reject</button>  
                                            
                                        </div>

                                        <div *ngIf="ishideparentbutton" class="col-md-12 text-right">
                                            <button class="btn btn-secondary btn-sm" (click)="ParentSubmit()">Submit</button>&nbsp;
                                            <a href="#" class="btn btn-danger btn-sm">Cancel</a>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
            
            
            
                    </div>
              
            </div>
                  </div>
                </div>
            </div>
        </div>

              </div>
             </div>
        </div>
    </div>
</div>

