import { Component, OnInit, AbstractType,TemplateRef, ElementRef } from '@angular/core';
import { StudentService } from '../../shared/student/student.service';
import { FormControl,FormsModule,FormBuilder,FormGroup,Validators, MaxLengthValidator } from '@angular/forms';
import { LoginService } from '../../shared/login/login.service';
import { StaffService } from '../../shared/staff/staff.service';
import Swal from 'sweetalert2';
import { DatePipe,formatDate } from '@angular/common';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import { ActivatedRoute,Routes } from '@angular/router';
import  {BsModalService,BsModalRef} from 'ngx-bootstrap/modal';
import {Title} from "@angular/platform-browser";
import { ViewChild } from '@angular/core';
import { StudentMessages } from '../student-messages';


@Component({
  selector: 'app-students-form',
  templateUrl: './students-form.component.html',
  styleUrls: ['./students-form.component.css']
})
export class StudentsFormComponent implements OnInit {
  datePickerConfig:Partial<BsDatepickerConfig>;
  modalRef: BsModalRef;  
  public user:any;
  public studentForm :FormGroup;
  public RejectForm:FormGroup;
  closeResult: string;
  formId:string;
  public data:any=[];
  public studenttable:FormGroup;
  //array to store family information
  public familyInformation:any=[];
  public employeeInfoform:any=[];
  public employeeInfoData:any=[];
  //Attachments form
  public attachmentform:FormGroup;

  //checkboxes variable declaration
  public isstudentattached:any;
  public isparentattached:any;
  public isstudentirsdt:any;
  public isparentirsdt:any;
  public isstudentfiled:any;
  public isparentfiled:any;
  //Documents form 
  public documents:FormGroup;
  //dependent and individual flags
  public isIndividual:any;
  public isDependent:any;
  //verified formgroup declaration
  public verifyForm:FormGroup;

  public currentDate:any;
  public Editindex:any;
  public Editempindex:any;

  public pupdate:boolean;
  public padd:boolean;
  public eadd:boolean;
  public eupdate:boolean;
  public ishidestudentrelatives:boolean;
  public ishidestudentemployee:boolean;
  public ishidestudentIncome:boolean;
  public ishideSubmit:boolean;
  public ishidestaffbutton:boolean;
  public ishideSidebar:boolean;
  public ishideparentbutton:boolean;
  public from:any;
  public formID:any;
  public link:any;
  public Staffname:string;
  public Employeeid:string;
  public reason:any;
  public mode:any;
  public irststudenttaxfile:any;
  public irstparenttaxfile:any;
  public irststudentw2:any;
  public irstparentw2:any;
  public gender:any;
  public martialstatus:any;
  public isparentemaildisable:boolean;
  public isdisablestudentfile:boolean;
  public isdisableparentfile:boolean;
  //public dob:string;
  constructor(private titleservice:Title,private fb: FormBuilder,private route:ActivatedRoute,private datepipe:DatePipe,private studentservice:StudentService,private loginService:LoginService,private staffservice:StaffService,private modalService: BsModalService) { 
    this.titleservice.setTitle("Verification Form");
    this.datePickerConfig=Object.assign({},
      { 
        containerClass:'theme-dark-blue',
        showWeekNumbers: false,
        dateInputFormat:'MM/DD/YYYY',
        isAnimated: true,
        
        
      });
      //DocumentsFormModel
      this.RejectForm=new FormGroup({
        reason:new FormControl()
      });
    this.documents = this.fb.group({
      studenttax:[''],
      parenttax:[''],
      studentw2:[''],
      parentw2:['']
    });
    
    //Attachment checkboxes
    this.attachmentform=fb.group({
      studentattached:[''],
      parentattached:[''],
      studentirsdt:[''],
      parentirsdt:[''],
      studentnofiling:[''],
      parentnofiling:[''],
    });

    //verified Form Model
    this.verifyForm = fb.group({
      verifUnTaxedInc:['',Validators.required],
      studentSignature:['',Validators.required],
      studentSignatureDate:['',Validators.required],
      parentSpouseSignature:['',Validators.required],
      parentSpouseSignatureDate :['',Validators.required],
      parentorSpouseEmail :['',Validators.required],
      signatureDate:['',Validators.required],
    });

    //student entry table form
    this.studenttable = fb.group({
      name:['',Validators.required],
      age:['',Validators.required],
      relationship:['',Validators.required],
      college:['',Validators.required]
    });

    //Employee Information from group
    this.employeeInfoform = fb.group({
      employee:['',Validators.required],
      studentannual:[''],
      spouseannual:[''],
      parentannual:['']
    });

    //student form group
    this.studentForm = fb.group({      
      firstName:['',Validators.required],
      lastName:['',Validators.required],
      middleName:['',Validators.required],
      studentID:['',Validators.required],
      ssNumber:['',Validators.required],
      gender: ['',Validators.required],
      dob:['',Validators.required],
      martialstatus:['',Validators.required],
      personalEmailAddress:[''],
      schoolEmailAddress:[''],
      address:[''],
      phoneNumber:[''],
      contactNumber:[''],
      city:[''],
      state:[''],
      zipcode:[''],
      currentTerm:[''],
      classification:[''],
      gradeName:[''],
    });
  }


  getCurrentDate(){
    let currentDate = Date.now();
    const cValue = formatDate(currentDate, 'MM/dd/yyyy', 'en-US');
    this.currentDate = cValue;
  }

  getCurrentYear(){
    let currentDate = Date.now();
    const cValue = formatDate(currentDate, 'MM/dd/yyyy', 'en-US');
    this.currentDate = cValue;
    let dobjarr = this.currentDate.split('/');
    let year = dobjarr[2];
    return year;
  }

  // @ViewChild('studenttaxfile') studenttax:ElementRef;
  // @ViewChild('parenttaxfile') parenttax:ElementRef;
  // @ViewChild('studentw2file') studentw2file:ElementRef;
  // @ViewChild('parentw2file') parentw2file:ElementRef;
  ngOnInit(): void {  
    //this.verifyForm
    //gender object
   

    this.getCurrentDate();
    this.pupdate=false;
    this.padd=true;
    this.eupdate = false;
    this.eadd = true;
    this.verifyForm.get("signatureDate").setValue(this.currentDate);
    this.verifyForm.get("parentSpouseSignatureDate").setValue(this.currentDate);
    this.verifyForm.get("studentSignatureDate").setValue(this.currentDate);
    this.isstudentattached=false;
    this.isparentattached=false;
    this.isstudentirsdt=false;
    this.isparentirsdt=false;
    this.isstudentfiled=false;
    this.isparentfiled=false;
    this.ishidestudentIncome = false;
    this.ishideSubmit = true;
    this.ishidestaffbutton = false;
    this.ishideSidebar=true;
    this.ishideparentbutton=false;
    this.isdisablestudentfile=true;
    this.isdisableparentfile=true;    

    this.formID = this.route.snapshot.queryParams["formid"];
    this.from = this.route.snapshot.queryParams["from"];
    this.mode=this.route.snapshot.queryParams["mode"];

    localStorage.setItem("formid",this.formID);
    localStorage.setItem("mode",this.mode);


    if(this.from=="staff"){
      this.ishidestaffbutton=true;
    }else if(this.from=="parent"){
      $("#container").removeClass("main-container");
       $(".main-content").removeAttr("id");
       $(".sidebarCollapse").hide();
      this.ishideSidebar=false;
      this.ishidestaffbutton=false;
      this.ishideparentbutton=true;
    }else if(this.from!="parent"&&this.from!="staff"){
      this.from="student";
    }

     //Getting the current session from the local storage
     this.user = this.loginService.getUser(); 
     if(this.user!=null){
        if(this.from!="parent" && this.user.role!="student"){
          if(this.user.role!="staff"){
            window.location.href="staff";
          }
        }
      }else if(this.from!="parent"){
        window.location.href="student";
      }
 
     if(this.from !="parent" && this.from !="student")
     {
       this.Staffname=this.user.firstName+' '+this.user.lastName;
       this.Employeeid=this.user.employeeId;
     }

    if(this.formID==""||this.formID==undefined){

       this.getForm();

    }else{
      $("#studentSignature").attr("disabled","disabled");
      $("#studentSignatureDate").attr("disabled","disabled");
      $("#parentorSpouseEmail").attr("disabled","disabled");
       this.getFormData();

    }

    //$("#animated-underline-home").click();

    this.active('animated-underline-home','animated-underline-profile','animated-underline-contact','animated-underline-contact1');
    // this.isstudentattached = true;
    // this.isparentattached = true;

    if(this.from=="parent"){
      $("#studentSignature").attr("disabled","disabled");
      $("#studentSignatureDate").attr("disabled","disabled");
      $("#parentorSpouseEmail").attr("disabled","disabled");
      $("#signatureDate").attr("disabled","disabled");
    }
    if(this.from=="staff"){
      $("#studentSignature").attr("disabled","disabled");
      $("#studentSignatureDate").attr("disabled","disabled");
      $("#parentorSpouseEmail").attr("disabled","disabled");
      $("#signatureDate").attr("disabled","disabled");
      $("#verifUnTaxedInc").attr("disabled","disabled");
      
    }
  }

  openModalWithClass(template: TemplateRef<any>) {  
    this.modalRef = this.modalService.show(  
      template,  
      Object.assign({}, { class: 'gray modal-md' })  
    );  
  }  
  saveRequestDoc(){
      window.location.href="requestdocument?Rid="+this.formID;
  }
  saveAcceptDocument(){  
    let obj={        
      employeeid:this.Employeeid,
      formid:this.formID,
      staffname:this.Staffname,       
    }
    this.staffservice.SaveAcceptDocuemnt(obj).subscribe(res=>{  
       if(res.status==1){
        window.location.href="dashboard/staff";
       }else {
         Swal.fire({
           icon: 'warning',
           title: 'Warning',
           text: StudentMessages.FORM_DOCUMENT_ACCEPT_ERROR
         });
       }
      });
  }

  saveReason(){
    
    if(this.RejectForm.value.reason==null)
    {
       Swal.fire("Warning",StudentMessages.FORM_ENTER_REASON_SUBMITTED,"warning");
       return;
    }
    else
    {
      let obj={
        employeeid:this.Employeeid,
        formid:this.formID,
        staffname:this.Staffname,
        reason:this.RejectForm.value.reason,
      }
      this.staffservice.submitRejectForm(obj).subscribe(res=>{
        if(res.status==1){
         window.location.href="dashboard/staff";
        }
        else
        {
          Swal.fire("Warning",StudentMessages.FORM_ENTER_REASON_NOTSUBMITTED,"warning");
        }
      });
    }

  }
  //method to change dateformat
  changeDateFormat(value: string) {
    var datePipe = new DatePipe("en-US");
     value = datePipe.transform(value, 'MM-dd-yyyy');
     return value;
 }

  //function to get student data based on student id 
  public CalculateAge(year)
  {
    
    let age:number;
      age =0;
    let currentYear= this.getCurrentYear();

    for(let i=year;i<currentYear;i++){
      age=age+1;
    }
     
   // console.log(age);
     return age;
 }
  //function to get student data based on student id 
  getForm(){
    this.ishidestudentrelatives=true;
      this.ishidestudentemployee=true;
      this.ishidestudentIncome = true;
      this.ishideSubmit = true;
      $("#InDependent").prop("disabled",true);
      this.staffservice.getStudentsByStudentID(this.user.studentID).subscribe(res=>{
      //binding the result to student form group
      this.studentForm = this.fb.group({
        firstName:[res.data[0].firstName,Validators.required],
        lastName:[res.data[0].lastName,Validators.required],
        middleName:[res.data[0].middleName,Validators.required],
        studentID:[res.data[0].studentID,Validators.required],
        ssNumber:[res.data[0].socialSecurityNo,Validators.required],
        gender: [res.data[0].gender,Validators.required],
        dob:[res.data[0].dob,Validators.required],
        martialstatus:[res.data[0].maritalStatus,Validators.required],
        personalEmailAddress:[res.data[0].personalEmailAddress],
        schoolEmailAddress:[res.data[0].schoolEmailAddress],
        address:[res.data[0].address],
        phoneNumber:[res.data[0].cellPhoneNumber],
        contactNumber:[res.data[0].contactNumber],
        city:[res.data[0].city],
        state:[res.data[0].state],
        zipcode:[res.data[0].zipcode],
        currentTerm:[res.data[0].currentTerm],
        classification:[''],
        gradeName:[res.data[0].gradeName]
      });
        if(res.data[0].dependentStatus == "I" && res.data[0].maritalStatus == "Single"){
          //this.verifyForm.parentorSpouseEmail
           $("#parentorSpouseEmail").attr("disabled", "disabled");
           $("#dvParent").hide();
        }

          if(res.data[0].dependentStatus == "D"){
            this.isDependent = true;
            this.isIndividual = false;
          }else{
            this.isDependent = false;
            this.isIndividual=true;
          }

      this.gender = res.data[0].gender;
      this.martialstatus = res.data[0].maritalStatus;
      this.irststudenttaxfile = res.data[0].irsTaxRetStudentfilepath;
      this.irstparenttaxfile = res.data[0].irsTaxRetParentfilepath;
      this.irststudentw2 = res.data[0].studentW2filepath;
      this.irstparentw2 = res.data[0].parentW2filepath;

       // let timeDiff = Math.abs(Date.now() - res.data[0].dob.getTime());
      // let age = Math.floor((timeDiff / (1000 * 3600 * 24))/365.25);
      let date:number;
      let dobyear = res.data[0].dob.split('-');
      date = dobyear[2];
     let years = this.CalculateAge(date);
     let obj = {
       name:res.data[0].firstName +" "+ res.data[0].lastName,
       age:years,
       relationship:'SELF',
       college:'ALASU'
     }

     this.familyInformation.push(obj);
    });
  }
  //function to Add familyinformation to the table
  AddStudentData(){

    if(!this.studenttable.valid){
      Swal.fire("Warning",StudentMessages.FORM_ALLREQUIRED_FIELDS,"warning");
      return false;
    }

    let obj={
      name:this.studenttable.value.name,
      age:this.studenttable.value.age,
      relationship:this.studenttable.value.relationship,
      college:this.studenttable.value.college
    }

    this.familyInformation.push(obj);
    this.cancelfamlyinfo();

  }

  // to clear the data of family information fields
  cancelfamlyinfo(){
    this.studenttable = this.fb.group({
      name:['',Validators.required],
      age:['',Validators.required],
      relationship:['',Validators.required],
      college:['',Validators.required]
    });
  }
//Emplooyee info Add method
  AddemployeeInfo(){
    // if(this.employeeInfoform.value.employee==null||this.employeeInfoform.value.employee==undefined){
    //   Swal.fire("Warning!","Name is required","warning");
    //   return false;
    // }else if(this.employeeInfoform.value.studentannual==null||this.employeeInfoform.value.studentannual==undefined){
      
    // }
    if(!this.employeeInfoform.valid){
      Swal.fire("Warning!",StudentMessages.FORM_ALLREQUIRED_FIELDS,"warning");
      return false;
    }
    let obj = {
      employee:this.employeeInfoform.value.employee,
      studentannual:this.employeeInfoform.value.studentannual,
      spouseannual:this.employeeInfoform.value.spouseannual,
      parentannual:this.employeeInfoform.value.parentannual
    }
    //add each object to employeeinfodata array
    this.employeeInfoData.push(obj);
    this.cancelemployeeInfo();
  }

  cancelemployeeInfo(){
    // To clear Employee Information from Fields
    this.employeeInfoform = this.fb.group({
      employee:['',Validators.required],
      studentannual:[''],
      spouseannual:[''],
      parentannual:['']
    });
  }

  studentattached(event){
    
    if(event.target.checked){
      this.attachmentform.get("studentattached").setValue(true);
      this.isstudentattached = true;
      this.isstudentirsdt = false;
      this.isstudentfiled = false;
      var $el=$("#swfile");
      $el.val("");
      this.isdisablestudentfile=false;
    }else{
      
      this.attachmentform.get("studentattached").setValue(false);
      this.documents.get('studenttax').setValue("");
      var $el=$("#sfile");
      $el.val("");
      this.isdisablestudentfile=true;
      this.isstudentattached = false;
    }
    
  }

  parentattached(event){
    if(event.target.checked){
    this.attachmentform.get("parentattached").setValue(true);
    this.isparentattached=true;
    this.isparentirsdt=false;
    this.isparentfiled=false;
    var $pa=$("#swfile");
    $pa.val("");
    this.isdisableparentfile=false;
    }else{
      this.attachmentform.get("parentattached").setValue(false);
      this.documents.get('parenttax').setValue("");
      var $pa=$("#pfile");
      $pa.val("");      
      this.isdisableparentfile=true;
      this.isparentattached=false;
    }
  }

  studentirstdt(event){
    if(event.target.checked){
      this.isstudentattached=false;
      this.isstudentirsdt=true;
      this.isstudentfiled=false;
      this.attachmentform.get("studentirsdt").setValue(true);
      var $el=$("#sfile");
      $el.val("");
      var $e2=$("#swfile");
      $e2.val("");

    }else{
      this.isstudentirsdt=false;
      this.attachmentform.get("studentirsdt").setValue(false);
    }
  }

  parentirstdt(event){
    if(event.target.checked){
      this.isparentattached=false;
      this.isparentirsdt = true;
      this.isparentfiled=false;     
      this.attachmentform.get("parentirsdt").setValue(true);
      var $pl=$("#pfile");
      $pl.val("");
      var $p2=$("#pwfile");
      $p2.val("");

    }else{
      this.isparentirsdt = false;
      this.attachmentform.get("parentirsdt").setValue(false);
    }
  }

  studentnofiling(event){
    if(event.target.checked){
      this.isstudentattached=false;
      this.isstudentirsdt = false;
      this.isstudentfiled=true;
      this.attachmentform.get("studentnofiling").setValue(true);
      var $e3=$("#sfile");
      $e3.val("");
    }else{
      this.isparentfiled =false;
      this.attachmentform.get("studentnofiling").setValue(false);
    }
  }

  parentnofiling(event){
    if(event.target.checked){
      this.isparentattached = false;
      this.isparentirsdt=false;
      this.isparentfiled=true;
    this.attachmentform.get("parentnofiling").setValue(true);
    var $p3=$("#pfile");
    $p3.val("");
    }else{
      this.isparentfiled=false;
      this.attachmentform.get("parentnofiling").setValue(false);
    }
  }

  //method is to upfilepload the student tx file
  studenttaxfile(event){
    if(event.target.files.length > 0){
      const file = event.target.files[0];
        if(!this.validateFile(file.name)){
        
          Swal.fire("Warning!",StudentMessages.FORM_SWAL_FILEFORMATS,"warning");
         // this.studenttax.nativeElement.value = "";
         var $s1=$("#sfile");
         $s1.val("");
          return false;          
        }
      this.documents.get('studenttax').setValue(file);
      
    }
  }
  //method is to upload the parenttax file
  parenttaxfile(event){
    if(event.target.files.length > 0){
      const file = event.target.files[0];
      if(!this.validateFile(file.name)){
        Swal.fire("Warning!",StudentMessages.FORM_SWAL_FILEFORMATS,"warning");
        var $p1=$("#pfile");
        $p1.val("");
        //this.parenttax.nativeElement.value="";
        return;
      }
      this.documents.get('parenttax').setValue(file);
    }
  }
  //method is to upload the studentw2 file
  studentw2(event){
    if(event.target.files.length > 0){
      const file = event.target.files[0];
      if(!this.validateFile(file.name)){
        Swal.fire("Warning!",StudentMessages.FORM_SWAL_FILEFORMATS,"warning");
        //this.studentw2file.nativeElement.value="";
        var $sw=$("#swfile");
        $sw.val("");
        return false;
      }
      this.documents.get('studentw2').setValue(file);
    }
  }
  //method is to upload the parentw2 file
  parentw2(event){
    if(event.target.files.length > 0){
      const file = event.target.files[0];
      if(!this.validateFile(file.name)){
        Swal.fire("Warning!",StudentMessages.FORM_SWAL_FILEFORMATS,"warning");
        //this.parentw2file.nativeElement.value="";
        var $pw=$("#pwfile");
        $pw.val("");
        return false;
      }
      this.documents.get('parentw2').setValue(file);
    }
  }

  individualChange(event){
    if(event.target.checked){
      this.isIndividual = true;
      this.isDependent = false;
    }else{
      this.isIndividual = false;
    }
  }

  dependentChange(event){
    if(event.target.checked){
      this.isDependent = true;
      this.isIndividual = false;
    }else{
      this.isDependent = false;
    }
  }

  SaveForm(){
    //checking the validation
    debugger;
    if(!this.isstudentattached && !this.isstudentfiled && !this.isstudentirsdt)
    {
      Swal.fire("Warning!","Please select one option for student tax file.","warning");
      return false;
    }
    if(this.isstudentattached){
      if(this.irststudenttaxfile == "" || this.irststudenttaxfile == null)
      {
          if(this.documents.value.studenttax==""){
            Swal.fire("Warning!","Please upload the student tax-file.","warning");
            return false;
          }
      }
     }

     if(this.isparentattached){
      if(this.irstparenttaxfile == "" || this.irstparenttaxfile == null)
      {
        if(this.documents.value.parenttax==""){
          Swal.fire("Warning!","Please upload the parent tax-file.","warning");
          return false;
        }
      }
     }
    

    

  //if(!this.isIndividual){
    if(this.verifyForm.value.studentSignature==""){
      Swal.fire("Warning!","Please enter student signature.","warning");
      return false;
    }

    if(this.verifyForm.value.studentSignatureDate==""){
      Swal.fire("Warning!","Please enter student signature date.","warning");
      return false;
    }

    if(this.verifyForm.value.signatureDate==""){
      Swal.fire("Warning!","Please enter signature date.","warning");
      return false;
    }

    if(this.isDependent || this.martialstatus=="Married")
    {
      if(this.verifyForm.value.parentorSpouseEmail==""){
        Swal.fire("Warning!","Please enter parent/spouse email.","warning");
        return false;
      }
    }
    
  
    //console.log("saving part");
    //creating form data
    const formData= new FormData();
    //appending data to form data
    formData.append('socialsecurityno',this.studentForm.value.ssNumber);
    formData.append('address',this.studentForm.value.address);
    formData.append('cellphonenumber',this.studentForm.value.phoneNumber);
    formData.append('contactnumber',this.studentForm.value.contactNumber);
    formData.append('city',this.studentForm.value.city);
    formData.append('state',this.studentForm.value.state);
    formData.append('zip',this.studentForm.value.zipcode);
    formData.append('grade',this.studentForm.value.gradeName);
    formData.append('studenttax', this.documents.value.studenttax);
    formData.append('parenttax', this.documents.value.parenttax);
    formData.append('studentw2',this.documents.value.studentw2);
    formData.append('parentw2',this.documents.value.parentw2);
    formData.append('studentID',this.user.studentID);
    formData.append('isAttachedIRSTaxRetStudent',this.attachmentform.value.studentattached==true?'1':'0');
    formData.append('isAttachedIRSTaxRetParent', this.attachmentform.value.parentattached==true?'1':'0');
    formData.append('isStudentUsedIRSDataRet', this.attachmentform.value.studentirsdt==true?'1':'0'); 
    formData.append('isParentUsedIRSDataRet', this.attachmentform.value.parentirsdt==true?'1':'0');
    formData.append('isStudentNoFiling', this.attachmentform.value.studentnofiling==true?'1':'0');
    formData.append('isParentNoFiling', this.attachmentform.value.parentnofiling==true?'1':'0');
    formData.append('verifUnTaxedInc', this.verifyForm.value.verifUnTaxedInc);
    formData.append('studentSignature', this.verifyForm.value.studentSignature);
    formData.append('parentSpouseSignature', this.verifyForm.value.parentSpouseSignature); 
    formData.append('signatureDate',this.changeDateFormat(this.verifyForm.value.signatureDate));
    formData.append('parentorSpouseEmail', this.verifyForm.value.parentorSpouseEmail); 
    formData.append('studentSignatureDate',this.changeDateFormat(this.verifyForm.value.studentSignatureDate));
    formData.append('parentSpouseSignatureDate',this.changeDateFormat(this.verifyForm.value.parentSpouseSignatureDate));
    formData.append('formStatus', "Student Submitted");
    formData.append('studentIncome', JSON.stringify(this.employeeInfoData));
    formData.append('studentRelatives', JSON.stringify(this.familyInformation));

    

    this.studentservice.submitForm(formData).subscribe(res=>{
      if(res.status==1){
        Swal.fire("Success!",StudentMessages.FORM_SUBMITTED_SUCCESS,"success");
        window.location.href="dashboard/student";
      }else{
        Swal.fire("Warning!",StudentMessages.FORM_SUBMITTED_ERROR,"warning");
      }
    });
  }
  //Navigation
  next(id,previd)
  {
   // alert(id); alert(previd);
    document.getElementById(id+'-tab').setAttribute("aria-selected", "true");
    document.getElementById(id+'-tab').setAttribute("class", "nav-link active");
    document.getElementById(id+'-tab').setAttribute("href", "#animated-underline-profile");
    document.getElementById(id).setAttribute("class", "tab-pane fade show active pl-4");

    document.getElementById(previd).setAttribute("class", "tab-pane fade pl-4");
    document.getElementById(previd+'-tab').setAttribute("aria-selected", "false");
    document.getElementById(previd+'-tab').setAttribute("class", "nav-link");


  }
  active(id,prev1,prev2,prev3)
  {
    document.getElementById(id+'-tab').setAttribute("aria-selected", "true");
    document.getElementById(id+'-tab').setAttribute("class", "nav-link active");
    document.getElementById(id+'-tab').setAttribute("href", "#animated-underline-profile");
    document.getElementById(id).setAttribute("class", "tab-pane fade show active pl-4");

    document.getElementById(prev1).setAttribute("class", "tab-pane fade pl-4");
    document.getElementById(prev1+'-tab').setAttribute("aria-selected", "false");
    document.getElementById(prev1+'-tab').setAttribute("class", "nav-link");

    
    document.getElementById(prev2).setAttribute("class", "tab-pane fade pl-4");
    document.getElementById(prev2+'-tab').setAttribute("aria-selected", "false");
    document.getElementById(prev2+'-tab').setAttribute("class", "nav-link");

    
    document.getElementById(prev3).setAttribute("class", "tab-pane fade pl-4");
    document.getElementById(prev3+'-tab').setAttribute("aria-selected", "false");
    document.getElementById(prev3+'-tab').setAttribute("class", "nav-link");
  }
  relationshipEdit(index){
      this.padd = false;
      this.pupdate = true;
      this.Editindex = index;
      const data =this.familyInformation[index];
      this.studenttable = this.fb.group({
        name:[data.name,Validators.required],
        age:[data.age,Validators.required],
        relationship:[data.relationship,Validators.required],
        college:[data.college,Validators.required]
      });
  }
  relationshipDelete(value){
      this.familyInformation.splice(value);
  }
  updaterelationship(){
      let familydt = this.familyInformation;
      this.familyInformation = [];
     // console.log(familydt);
      for(let i=0;i<familydt.length;i++){
        if(this.Editindex == i){
          let obj={
            name:this.studenttable.value.name,
            age:this.studenttable.value.age,
            relationship:this.studenttable.value.relationship,
            college:this.studenttable.value.college,
          }
          this.familyInformation.push(obj);
        }else{
          let obj={
            name:familydt[i].name,
            age:familydt[i].age,
            relationship:familydt[i].relationship,
            college:familydt[i].college,
          }
          this.familyInformation.push(obj);
        }
      }

      this.cancelfamlyinfo();
      this.padd = true;
      this.pupdate = false;
  }
  employeeEdit(index){
      this.Editempindex = index;
      const dt = this.employeeInfoData[index];
      this.employeeInfoform = this.fb.group({
        employee:[dt.employee,Validators.required],
        studentannual:[dt.studentannual],
        spouseannual:[dt.spouseannual],
        parentannual:[dt.parentannual]
      });
      this.eadd = false;
      this.eupdate=true;
  }
  employeeDelete(val){
      this.employeeInfoData.splice(val);
  }
  employeeUpdate(){
      let empdata = this.employeeInfoData;
      this.employeeInfoData=[];
     // console.log(this.employeeInfoform.value);
      for(let i=0;i<empdata.length;i++){

        if(this.Editempindex == i){
          let obj={
            employee:this.employeeInfoform.value.employee,
            studentannual:this.employeeInfoform.value.studentannual,
            spouseannual:this.employeeInfoform.value.spouseannual,
            parentannual:this.employeeInfoform.value.parentannual
          }
          this.employeeInfoData.push(obj);
        }else{
          let obj={
            employee:empdata[i].employee,
            studentannual:empdata[i].studentannual,
            spouseannual:empdata[i].spouseannual,
            parentannual:empdata[i].parentannual
          }
          this.employeeInfoData.push(obj);
        }

      }

      this.eadd = true;
      this.eupdate=false;
      this.cancelemployeeInfo();
  }
  getFormData(){
      this.ishidestudentrelatives=false;
      this.ishidestudentemployee=false;
      this.ishidestudentIncome = false;
      this.ishideSubmit = false;
      this.staffservice.getFormByFormID(this.formID).subscribe(res=>{
        if(res.status==1){
          //checking the formstatus
              if(this.from=="parent"){
                if(res.data[0].formStatus !="Student Submitted"){
                  localStorage.setItem('info',StudentMessages.FORM_INFO_PARENTSUBMITTED);
                  window.location.href="info";
                }
              }

              if(this.from=="staff"){
                if(res.data[0].formStatus=="Counselor Accepted"||res.data[0].formStatus=="Counselor Rejected"){
                  localStorage.setItem('info','Form already submitted');
                  window.location.href="info";
                }
              }
            //bind student information form
            this.studentForm = this.fb.group({
              firstName:[res.data[0].firstName],
              lastName:[res.data[0].lastName,Validators.required],
              middleName:[res.data[0].middleName,Validators.required],
              studentID:[res.data[0].studentID,Validators.required],
              ssNumber:[res.data[0].socialSecurityNo,Validators.required],
              gender: [res.data[0].gender=="M"?"Male":"Female",Validators.required],
              dob:[res.data[0].dob,Validators.required],
              martialstatus:[res.data[0].maritalStatus,Validators.required],
              personalEmailAddress:[res.data[0].personalEmailAddress],
              schoolEmailAddress:[res.data[0].schoolEmailAddress],
              address:[res.data[0].address],
              phoneNumber:[res.data[0].cellPhoneNumber],
              contactNumber:[res.data[0].contactNumber],
              city:[res.data[0].city],
              state:[res.data[0].state],
              zipcode:[res.data[0].zipcode],
              currentTerm:[res.data[0].currentTerm],
              classification:[''],
              gradeName:[res.data[0].gradeName]
            });
            this.gender = res.data[0].gender=="M"?"Male":"Female";
            this.martialstatus = res.data[0].maritalStatus;
            this.irststudenttaxfile = res.data[0].irsTaxRetStudentfilepath;
            this.irstparenttaxfile = res.data[0].irsTaxRetParentfilepath;
            this.irststudentw2 = res.data[0].studentW2filepath;
            this.irstparentw2 = res.data[0].parentW2filepath;
            //End of binding student form

            //setting the dependent status
              if(res.data[0].dependentStatus == "D"){
                this.isDependent = true;
                this.isIndividual  =false;
              }else{
                this.isDependent = false;
                this.isIndividual  =true;
              }
            //End setting the dependent status
              //Disabling parent/ spouse Email
              if(res.data[0].dependentStatus == "I" && res.data[0].maritalStatus == "Single"){
                //this.verifyForm.parentorSpouseEmail
                 $("#parentorSpouseEmail").attr("disabled", "disabled");
                 $("#dvParent").hide();
              }
            //setting the student relatives
            if(res.data[0].studentRelatives.length>0){
              this.familyInformation=[];
              for(let i=0;i<res.data[0].studentRelatives.length;i++){
                let obj={
                  name:res.data[0].studentRelatives[i].name,
                  age:res.data[0].studentRelatives[i].age,
                  relationship:res.data[0].studentRelatives[i].relationship,
                  college:res.data[0].studentRelatives[i].college
                }
                this.familyInformation.push(obj);
              }
            }
            //End setting the student relatives


            //binding the checkboxes
            this.isparentattached = res.data[0].isAttachedIRSTaxRetParent==1?true:false;
            this.isstudentattached=res.data[0].isAttachedIRSTaxRetStudent==1?true:false;
            this.isstudentirsdt= res.data[0].isStudentUsedIRSDataRet==1?true:false;
            this.isparentirsdt = res.data[0].isParentUsedIRSDataRet==1?true:false;
            this.isstudentfiled =  res.data[0].isStudentNoFiling==1?true:false;
            this.isparentfiled = res.data[0].isParentNoFiling==1?true:false;
            //End of binding the checkboxes

            //Binding Student Income

            if(res.data[0].studentIncome.length > 0){
              this.employeeInfoData=[];
              for(let j=0;j<res.data[0].studentIncome.length;j++){
                let obj={
                  employee:res.data[0].studentIncome[j].employee,
                  studentannual:res.data[0].studentIncome[j].studentannual==null?"":res.data[0].studentIncome[j].studentannual,
                  spouseannual:res.data[0].studentIncome[j].spouseannual==null?"":res.data[0].studentIncome[j].spouseannual,
                  parentannual:res.data[0].studentIncome[j].parentannual==null?"":res.data[0].studentIncome[j].parentannual
                }
                this.employeeInfoData.push(obj);
              }

            }

            //End Binding student income

            //Binding Verification Form
            this.verifyForm = this.fb.group({
              verifUnTaxedInc:res.data[0].verifUnTaxedInc,
              studentSignature:res.data[0].studentSignature,
              studentSignatureDate:res.data[0].studentSignatureDate,
              parentSpouseSignature:res.data[0].parentSpouseSignature,
              parentSpouseSignatureDate :res.data[0].parentSpouseSignatureDate,
              parentorSpouseEmail :res.data[0].parentorSpouseEmail,
              signatureDate:res.data[0].signatureDate,
            });

            //End of Binding verification Form


        }else{
            //ELSE LOGIC HERE------
        }
      });

  }
  ParentSubmit(){
    debugger;
    if(!this.isparentattached && !this.isparentfiled && !this.isparentirsdt)
    {
      Swal.fire("Warning!","Please select one option for parent tax file.","warning");
      return false;
    }
    if(this.isparentattached){
      if(this.irstparenttaxfile == "" || this.irstparenttaxfile == null)
      {
        if(this.documents.value.parenttax==""){
          Swal.fire("Warning!","Please upload the parent tax-file.","warning");
          return false;
        }
      }
     }

    if(this.verifyForm.value.parentSpouseSignature==""){
      Swal.fire("Warning!","Please enter parent signature.","warning");
      return false;
    }

    if(this.verifyForm.value.parentSpouseSignatureDate==""){
      Swal.fire("Warning!","Please enter parent signature date.","warning");
      return false;
    }
   

      const formData= new FormData();
    //appending data to form data
      formData.append('studenttax', this.documents.value.studenttax);
      formData.append('parenttax', this.documents.value.parenttax);
      formData.append('studentw2',this.documents.value.studentw2);
      formData.append('parentw2',this.documents.value.parentw2);
      formData.append('formid',this.formID);
      formData.append('isAttachedIRSTaxRetStudent',this.attachmentform.value.studentattached==true?'1':'0');
      formData.append('isAttachedIRSTaxRetParent', this.attachmentform.value.parentattached==true?'1':'0');
      formData.append('isStudentUsedIRSDataRet', this.attachmentform.value.studentirsdt==true?'1':'0'); 
      formData.append('isParentUsedIRSDataRet', this.attachmentform.value.parentirsdt==true?'1':'0');
      formData.append('isStudentNoFiling', this.attachmentform.value.studentnofiling==true?'1':'0');
      formData.append('isParentNoFiling', this.attachmentform.value.parentnofiling==true?'1':'0');
      formData.append('parentSpouseSignature', this.verifyForm.value.parentSpouseSignature); 
      formData.append('parentSpouseSignatureDate',this.changeDateFormat(this.verifyForm.value.parentSpouseSignatureDate));
      formData.append('formStatus', "Parent/Spouse Accepted");
      
      // if(this.documents.value.parenttax==null||this.documents.value.parenttax==undefined||this.documents.value.parenttax==""){
      //   Swal.fire("Warning!","Please Upload the parent tax-file.","warning");
      //   return false;
      // }

      

      this.studentservice.Parentsubmit(formData).subscribe(res=>{
        if(res.status==1){
          Swal.fire("Success!",StudentMessages.FORM_SUBMITTED_SUCCESS,"success");
          localStorage.setItem("info",StudentMessages.FORM_SUBMITTED_SUCCESS);
          window.location.href="info";
        }else{
          Swal.fire("Warning!",StudentMessages.FORM_SUBMITTED_ERROR,"warning");
          return false;
        }
      });

  }
  ViewPDF(file)
    {
      var base64EncodedPDF="";
      this.staffservice.getPdf(file).subscribe(res=>{
        if(res.status==1){
            var sampleArr = this.staffservice.base64ToArrayBuffer(res.data);
            this.staffservice.saveByteArray(file, sampleArr);
        }
      });
  }

  //File validation example
    validateFile(name: String) {
      var ext = name.substring(name.lastIndexOf('.') + 1);
      if (ext.toLowerCase() == 'png') {
          return true;
      }else if(ext.toLowerCase() =='jpeg'){
        return true;
      }else if(ext.toLowerCase() =='jpg'){
        return true;
      }else if(ext.toLowerCase() =='pdf'){
        return true;
      }else if(ext.toLowerCase() =='dmp'){
        return true;
      }else {
          return false;
      }
    }
}

