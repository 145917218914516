import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../shared/login/login.service';
import { StaffService } from '../../shared/staff/staff.service';
import {Title} from "@angular/platform-browser";
declare var $;
@Component({
  selector: 'app-staff-master',
  templateUrl: './staff-master.component.html',
  styleUrls: ['./staff-master.component.css']
})
export class StaffMasterComponent implements OnInit {
  public rowDataProgress:any=[];
  public rowDataCompleted:any=[];
  public id:any;
  public formid:string ="";

  constructor(private titleservice:Title,public loginService:LoginService,private staffservice:StaffService) {
    this.titleservice.setTitle("Staff Dashboard");
    setTimeout(function(){
      $(function(){
        $("#tblSFProgres").DataTable({
          dom: '<"row"<"col-md-12"<"row"<"col-md-6"B><"col-md-6"f> > ><"col-md-12"rt> <"col-md-12"<"row"<"col-md-5"i><"col-md-7"p>>> >',    
          buttons: {          
            buttons: [              
                { extend: 'csv', className: 'btn' },
                { extend: 'excel', className: 'btn' },
                { extend: 'print', className: 'btn' }
            ]
        },
        "oLanguage": {
          "oPaginate": { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
          "sInfo": "Showing page _PAGE_ of _PAGES_",
          "sSearch": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
          "sSearchPlaceholder": "Search...",
         "sLengthMenu": "Results :  _MENU_",
      },
      "order": [[ 3, "desc" ]],
          "stripeClasses": [],
          "lengthMenu": [5, 10, 20, 50],
          "pageLength": 5,
          drawCallback: function () { $('.dataTables_paginate > .pagination').addClass(' pagination-style-13 pagination-bordered mb-5'); }
          
         });
         $("#tblSFCompleted").DataTable({
          dom: '<"row"<"col-md-12"<"row"<"col-md-6"B><"col-md-6"f> > ><"col-md-12"rt> <"col-md-12"<"row"<"col-md-5"i><"col-md-7"p>>> >',    
          buttons: {          
            buttons: [              
                { extend: 'csv', className: 'btn' },
                { extend: 'excel', className: 'btn' },
                { extend: 'print', className: 'btn' }
            ]
        },
        "oLanguage": {
          "oPaginate": { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
          "sInfo": "Showing page _PAGE_ of _PAGES_",
          "sSearch": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
          "sSearchPlaceholder": "Search...",
         "sLengthMenu": "Results :  _MENU_",
      },
      "order": [[ 3, "desc" ]],
          "stripeClasses": [],
          "lengthMenu": [5, 10, 20, 50],
          "pageLength": 5,
          drawCallback: function () { $('.dataTables_paginate > .pagination').addClass(' pagination-style-13 pagination-bordered mb-5'); }
          
         });
      });
      
    },3000);
   }
  
  ngOnInit(): void {
    let user=this.loginService.getUser();
    if(user!=null||user!=undefined){
      if(user.role!="staff"){
        window.location.href="staff";
      }
    }else{
      window.location.href="staff";
    }
    this.id = user.employeeId;
    this.getStaffData();
  }

  ViewPDF(file)
  {
    var base64EncodedPDF="";
    this.staffservice.getPdf(file).subscribe(res=>{
      if(res.status==1){
          var sampleArr = this.staffservice.base64ToArrayBuffer(res.data);
          this.staffservice.saveByteArray(file, sampleArr);
      }
    });
  }
  getStaffData(){

    this.staffservice.getFormsByStaffID(this.id).subscribe(res=>{
      if(res.status==1){
            for(let i=0;i<res.data.length;i++){
                if(res.data[i].formStatus.toLowerCase() == "counselor accepted" || res.data[i].formStatus.toLowerCase() == "counselor rejected" ){
                  let rej={
                    formid:res.data[i].id,
                    formName: res.data[i].formName,
                    studentID: res.data[i].studentID,
                    name: res.data[i].firstName + res.data[i].lastName,
                    taxYear: res.data[i].taxYear,
                    term: res.data[i].term,
                    formStatus: res.data[i].formStatus,
                    pdfFilepath: res.data[i].pdfFilepath
                  }
                  this.rowDataCompleted.push(rej);
                }else{          
                  let pgdt ={                    
                    formid:res.data[i].id,
                    formName: res.data[i].formName,
                    studentID: res.data[i].studentID,
                    name: res.data[i].firstName + res.data[i].lastName,
                    taxYear: res.data[i].taxYear,
                    term: res.data[i].term,
                    formStatus: res.data[i].formStatus,
                    pdfFilepath: res.data[i].pdfFilepath
                  }
                  this.rowDataProgress.push(pgdt);
                }

            }
          }

    });

  }

}
