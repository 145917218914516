import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';
import {AdminLoginComponent} from './login/admin-login/admin-login.component';
import { StaffLoginComponent } from './login/staff-login/staff-login.component';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { StaffDashboardComponent } from './staff/staff-dashboard/staff-dashboard.component';
import { StaffMasterComponent } from './staff/staff-master/staff-master.component';
import { AdminMasterComponent } from './admin/admin-master/admin-master.component';
import { CreateStaffComponent } from './admin/create-staff/create-staff.component';
import { ManageStaffComponent } from './admin/manage-staff/manage-staff.component';
import { StudentLoginComponent } from './login/student-login/student-login.component';
import { StudentMasterComponent } from './student/student-master/student-master.component';
import { StudentsFormComponent } from './student/students-form/students-Form.component';
import { AdminStudentlistComponent} from './admin/admin-studentlist/admin-studentlist.component';
import { AdminEditstudentComponent} from  './admin/admin-editstudent/admin-editstudent.component';
import { UploadStudentComponent } from './admin/upload-student/upload-student.component';
import { StudentListComponent } from './staff/student-list/student-list.component';
import { SearchStudentComponent } from './staff/search-student/search-student.component';
import {StaffResetpasswordComponent} from './staff/staff-resetpassword/staff-resetpassword.component';
import {AcademicTermComponent} from './student/academic-term/academic-term.component';
import {RequestdocumentComponent} from './staff/requestdocument/requestdocument.component';
import { InfoComponent } from './student/info/info.component';
import {StudentTimesheetComponent} from './student/student-timesheet/student-timesheet.component';
import {StudentWorkstudyComponent} from './student/student-workstudy/student-workstudy.component';

const routes: Routes = [
  { path: '',  component: StudentLoginComponent },
  { path: 'admin', component: AdminLoginComponent },
  { path: 'staff', component:StaffLoginComponent},
  { path: 'student', component:StudentLoginComponent},
  { path: 'dashboard/admin', component:AdminMasterComponent},
  { path: 'dashboard/staff', component:StaffMasterComponent},
  { path: 'dashboard/student',component:StudentMasterComponent},
  { path: 'manage-staff', component:ManageStaffComponent},
  { path: 'create-staff',component:CreateStaffComponent},
  { path: 'fafs',component:StudentsFormComponent},
  { path: 'admin-studentlist',component:AdminStudentlistComponent},
  { path: 'admin-editstudent',component:AdminEditstudentComponent},
  { path: 'update-profile',component:AdminEditstudentComponent},
  { path: 'upload-student',component:UploadStudentComponent},
  { path: 'student-list', component:StudentListComponent},
  { path: 'search-student', component:SearchStudentComponent},
  { path: 'students-form', component:StudentsFormComponent},
  { path: 'staff-resetpassword', component:StaffResetpasswordComponent},
  { path: 'academic-term', component:AcademicTermComponent},
  { path: 'requestdocument', component:RequestdocumentComponent},
  { path: 'viewpdf', component:StaffDashboardComponent},
  { path: 'info',component:InfoComponent},
  { path: 'student-timesheet',component:StudentTimesheetComponent},
{ path: 'student-workstudy',component:StudentWorkstudyComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { 
  
}
