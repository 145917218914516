<!-- <app-header></app-header> -->
<app-header></app-header>
  <!--  BEGIN MAIN CONTAINER  -->
  <div class="main-container" id="container">

    <div class="overlay"></div>
    <div class="search-overlay"></div>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">        
        <app-student-sidebar></app-student-sidebar>
    </div>
    <!--  END SIDEBAR  -->    
    <!--  BEGIN CONTENT AREA  -->
    <div id="content" class="main-content">
    <div class="layout-px-spacing">
      <div class="row layout-top-spacing">      
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">             
             <div class="row">
               <div class="col-md-12">
                 <ol class="breadcrumb">
                     <li class="breadcrumb-item ml-auto"><a href="dashboard/student">Dashboard</a></li>
                     <li class="breadcrumb-item active text-primary" aria-current="page"><span>Academic Term</span></li>
                 </ol>
              </div>
            </div>
                  <div class="row">
                        <div class="col-md-12">
                          <div class="card">
                            <div class="card-header bg-secondary">
                              <span class="text-white"><b>Academic Term</b></span>
                            </div>
                            <div class="card-body" style="height:450px;">
                              <div class="row">
                                <div class="col-md-12">
                                        <form [formGroup]="termgroup">
                                            <div class="row">
                                                    <div class="col-md-3">
                                                        <select class="form-control" formControlName="term">
                                                            <option value="" selected>Select Term</option>															
                                                               <option *ngFor="let term of terms; let i = index" [value]="terms[i].term">
                                                                {{terms[i].termName}}
                                                              </option>
                                                        </select>
                                                    </div>
                                                   
                                                    <div class="col-md-1"><button class="btn btn-success" (click)="submit()">Submit</button></div>
                                              </div>
                                        </form>
                                    </div>
                                </div>
                              
                             
                            </div>
                          </div>
                        </div>
                    </div>  
                
           
        </div>   
        <app-footer></app-footer>    
    </div>
  </div>

    <!--  END CONTENT AREA  -->
  </div>
</div>

<!-- END MAIN CONTAINER -->