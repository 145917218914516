import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../../shared/login/login.service';
import { from } from 'rxjs';
import { ActivatedRoute,Routes } from '@angular/router';


@Component({
  selector: 'app-staff-login',
  templateUrl: './staff-login.component.html',
  styleUrls: ['./staff-login.component.css']
})
export class StaffLoginComponent implements OnInit {

  loginForm = this.fb.group({
    username:'',
    password:''
  });

  constructor(private routing:ActivatedRoute,private fb:FormBuilder,private loginService: LoginService, private route: Router) { }

  ngOnInit(): void {
    this.loginService.logout();
  }

  login() {
    if (!this.loginForm.invalid) {    

      this.loginService.stafflogin(this.loginForm.value).subscribe(res =>{
        if (res.status == 1) {
          this.loginService.logout();
          res.data[0]['role'] = "staff";
          this.loginService.storeUserData(
            res.data[0].id,
            res.data[0]
          );
          //redirection
          const formid = this.routing.snapshot.queryParams["formid"];
          const mode = this.routing.snapshot.queryParams["mode"];
          //if formid and mode is equals to undefined then redirect to staff dashboard
          if(formid==null&&mode==null){
           window.location.href="dashboard/staff";
          }else if(mode=="v"){
            window.location.href="fafs?formid="+formid+"&from=staff";
          }else if(mode=="r"){
            window.location.href="requestdocument?Rid="+formid;
          }

        } else {
          Swal.fire('Login Fail.', 'Please check username and password.', 'error');
        }
      }, error => {
        Swal.fire("Warning!","Error","warning");
      });
    } else {
      Swal.fire('Oops..', 'Please fill all fields.', 'error');
    }
  }

}
