<app-header></app-header> 


<div class="main-container" id="container">
  <div class="overlay"></div>
  <div class="search-overlay"></div>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">        
        <app-admin-sidebar></app-admin-sidebar>
    </div>
    <!--  END SIDEBAR  -->    
    <!--  BEGIN CONTENT AREA  -->
    <div id="content" class="main-content">
    <div class="layout-px-spacing">
      <div class="row layout-top-spacing">      
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div class="row">
              <div class="col-md-12">
                <nav class="breadcrumb-one" aria-label="breadcrumb">
                  <ol class="breadcrumb">
                      <li class="breadcrumb-item ml-auto"><a href="dashboard/admin">Dashboard</a></li>
                      <li class="breadcrumb-item active text-primary" aria-current="page"><span>Admin</span></li>
                  </ol>
              </nav>

              </div>
            </div>
            <!-- First Grid Starts-->
                      <div class="row">
                        <div class="col-md-12">
                          <div class="card">
                            <div class="card-header bg-secondary">
                              <span class="text-white"><b>Student Forms Progress</b></span>
                            </div>
                            <div class="card-body">                      
                              <div class="table-responsive">
                                <table id="tblSFProgress" class="table table-striped table-bordered" width="100%">
                                  <thead>
                                    <tr>
                                        <th>Form Name</th>                                       
                                        <th>Student ID</th>
                                        <th>Student Name</th>
                                        <th>Tax Year</th>
                                        <th>Term</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                  </thead>
                                    <tbody>
                                        <tr *ngFor="let data of rowDataProgress">
                                            <td>{{data.formName}}</td>
                                            <td>{{data.studentID}}</td>
                                            <td>{{data.name}}</td>
                                            <td>{{data.taxYear}}</td>
                                            <td>{{data.term}}</td>
                                            <td>{{data.formStatus}}</td>
                                            <td>
                                               <a (click)="ViewPDF(data.pdfFilepath)" class="btn btn-success" title="print">Print</a> 
                                            </td>
                                          </tr>
                                    </tbody>
                                </table>
                            </div>                            
                            </div>
                          </div>
                        </div>
                    </div>  

                    <!-- First Grid Ends -->

                    <!-- Second Grid Starts -->
                    <br>
                    <div class="row">
                        <div class="col-md-12">
                          <div class="card">
                            <div class="card-header bg-secondary">
                              <span class="text-white"><b>Student Forms Completed</b></span>
                            </div>
                            <div class="card-body">                              
                              <div class="table-responsive">
                                <table id="tblSFCompleted" class="table table-striped table-bordered" width="100%">
                                  <thead>
                                    <tr>
                                        <th>Form Name</th>                                       
                                        <th>Student ID</th>
                                        <th>Student Name</th>
                                        <th>Tax Year</th>
                                        <th>Term</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                  </thead>
                                    <tbody>
                                      <tr *ngFor="let data of rowDataCompleted">
                                        <td>{{data.formName}}</td>
                                        <td>{{data.studentID}}</td>
                                        <td>{{data.name}}</td>
                                        <td>{{data.taxYear}}</td>
                                        <td>{{data.term}}</td>
                                        <td>{{data.formStatus}}</td>
                                        <td>
                                          <a (click)="ViewPDF(data.pdfFilepath)" class="btn btn-success" title="print">Print</a>                                          
                                        </td>
                                      </tr>
                                    </tbody>
                                </table>
                            </div>
                           
                            </div>
                          </div>
                        </div>
                    </div>  

                    <!-- Second Grid Ends -->
                
           
        </div>   
        <app-footer></app-footer>    
    </div>
  </div>

    <!--  END CONTENT AREA  -->
  </div>

</div>
