import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../shared/login/login.service';
import {StudentService } from '../../shared/student/student.service';
import { FormControl,FormsModule,FormBuilder,FormGroup,Validators } from '@angular/forms';
declare var $;

@Component({
  selector: 'app-student-timesheet',
  templateUrl: './student-timesheet.component.html',
  styleUrls: ['./student-timesheet.component.css']
})
export class StudentTimesheetComponent implements OnInit {
  public id:any;
  public rowDataTimesheet:any=[];
  public terms:string;
 

  constructor(private loginService:LoginService,private studentservice:StudentService,private fb: FormBuilder) { 

    setTimeout(function(){
      $(function(){
        $("#tblTimesheet").DataTable({   
          dom: '<"row"<"col-md-12"<"row"<"col-md-6"B><"col-md-6"f> > ><"col-md-12"rt> <"col-md-12"<"row"<"col-md-5"i><"col-md-7"p>>> >',    
          
          buttons: {          
              buttons: [              
                  { extend: 'csv', className: 'btn' },
                  { extend: 'excel', className: 'btn' },
                  { extend: 'print', className: 'btn' }
              ]
          },
         
          "oLanguage": {
            "oPaginate": { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
            "sInfo": "Showing page _PAGE_ of _PAGES_",
            "sSearch": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
            "sSearchPlaceholder": "Search...",
           "sLengthMenu": "Results :  _MENU_",
        },
        "order": [[ 3, "desc" ]],
        "stripeClasses": [],
        "lengthMenu": [5, 10, 20, 50],
        "pageLength": 5,
        drawCallback: function () { $('.dataTables_paginate > .pagination').addClass(' pagination-style-13 pagination-bordered mb-5'); }
        });

      });

    },3000);
  }

  ngOnInit(): void {
    let user=this.loginService.getUser();
      this.id=user.studentID;
      debugger
      this.getTimesheetData();
  }

  getTimesheetData(){
      this.studentservice.getTimesheet(this.id).subscribe(res=>{
          if(res.status==1){
            debugger
             for(let i=0;i<res.data.length;i++){
               let objTime={
                StudentId:res.data[i].studentId,
                Supervisor:res.data[i].supervisor,
                Month:res.data[i].timesheetMonth,
                Year:res.data[i].timesheetYear,
                Hours:res.data[i].hours,
                Status:res.data[i].status,
               }
               this.rowDataTimesheet.push(objTime);
             }              
           }
      });
 }

}
