import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { Constants } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class StaffService {

  constructor(private http: HttpClient) { }

  Baseurl(){
    return Constants.BASE_URL;
  }

  getStaff(): Observable<any>{
    return this.http.get<any>(this.Baseurl()+Constants.GET_STAFF);
  }

  getStaffById(id): Observable<any>{
    return this.http.get<any>(this.Baseurl()+Constants.GET_STAFF+"?id="+id);
  }

  CreateSatff(staffModel): any {    
    return this.http.post<any>(this.Baseurl()+Constants.STAFF_ADD,staffModel);
  }

  UploadFile(formData): any {
    //while uploading Files we should use headers
     const uploadReq = new HttpRequest('POST',Constants.BASE_URL + Constants.UPLOAD_FILES, formData);
    return this.http.request(uploadReq);
  }

  UpdateSatff(staffModel): any {    
    return this.http.post<any>(this.Baseurl()+Constants.UPDATE_STAFF,staffModel);
  }

  DeleteStaff(id):any{
    return this.http.get<any>(this.Baseurl()+Constants.DELETE_STAFF+"?id="+id);
  }

  getStudentList(): Observable<any>{
    return this.http.get<any>(this.Baseurl()+Constants.GET_STUDENTS);
  }
  getStudentById(id): Observable<any>{
    return this.http.get<any>(this.Baseurl()+Constants.GET_STUDENTS+"?id="+id);
  }

  UpdateStudent(studentModel): Observable<any>{
    return this.http.post<any>(this.Baseurl()+Constants.UPDATE_STUDENTS,studentModel);
  }

  DeleteStudents(id):any{
    return this.http.get<any>(this.Baseurl()+Constants.DELETE_STUDENTS+"?id="+id);
  }

  uploadstudent(student): any {    
    return this.http.get<any>(this.Baseurl()+Constants.UPLOAD_STUDENT+"?employeeid="+student.employeeid+"&studentdata="+student.studentdata);
  }

  getForms():any{
    return this.http.get<any>(this.Baseurl()+Constants.GET_FORMS);
    }
    getPdf(file):any{
      return this.http.post<any>(this.Baseurl()+Constants.GET_PDF+"?filepath=" + file,{responseType: 'arraybuffer'});
      }
    getFormsByStaffID(employeeid):any{
    return this.http.get<any>(this.Baseurl()+Constants.GET_FORMS+"?employeeid="+employeeid);
    }
    
    getFormsByStudentID(studentid):any{
    return this.http.get<any>(this.Baseurl()+Constants.GET_FORMS+"?studentid="+studentid);
    }
//getFormByFormID
getFormByFormID(formid):any{
  return this.http.get<any>(this.Baseurl()+Constants.GET_FORMS+"?id="+formid);
  }
    //getStudentsByStudentID

    getStudentsByStudentID(studentid):any{
      return this.http.get<any>(this.Baseurl()+Constants.GET_STUDENTS+"?studentid="+studentid);
      }

    getStudentListByStaffID(staffid,nameseries): Observable<any>{
      return this.http.get<any>(this.Baseurl()+Constants.GET_STUDENTS+"?employeeID="+staffid+"&nameseries="+nameseries);
      }

      getStudentForms(obj):any{
        return this.http.get<any>(this.Baseurl()+Constants.GET_FORMS+"?type=resend&employeeid="+obj.employeeid+"&nameseries="+obj.nameseries+"&studentid="+obj.studentid+"&firstname="+obj.firstname+"&lastname="+obj.lastname);
        }

        // getStudentForms(obj):any{
        //   return this.http.get<any>(this.Baseurl()+Constants.GET_FORMS+"?type=resend");
        //   }
          
          //employeeid="+obj.employeeid+"&nameseries="+obj.nameseries+"&studentid="+obj.studentid+"&firstname="+obj.firstname+"&lastname="+obj.lastname
          
          resendMail(studentModel): Observable<any>{
          return this.http.get<any>(this.Baseurl()+Constants.RESEND_EMAIL+"?formid="+studentModel.id+"&email="+studentModel.email);
          }
          UpdatePassword(staffModel): any {
            return this.http.get<any>(this.Baseurl()+Constants.UPDATE_PASSWORD+"?id="+staffModel.id+"&password="+staffModel.password);
            }

            SaveRequestDocuemnt(ReqDocumentModel):any{
              return this.http.get<any>(this.Baseurl()+Constants.SAVE_REQUESTDOCUEMNT+"?employeeid="+ReqDocumentModel.employeeid+"&formid="+ReqDocumentModel.formid+"&staffname="+ReqDocumentModel.staffname+"&studenttax="+ReqDocumentModel.studenttax+"&studentw2="+ReqDocumentModel.studentw2+"&parenttax="+ReqDocumentModel.parenttax+"&parentw2="+ReqDocumentModel.parentw2+"&reason="+ReqDocumentModel.reason);
              }
              SaveAcceptDocuemnt(ReqDocumentModel):any{
              return this.http.get<any>(this.Baseurl()+Constants.SAVE_ACCEPTDOCUEMNT+"?employeeid="+ReqDocumentModel.employeeid+"&formid="+ReqDocumentModel.formid+"&staffname="+ReqDocumentModel.staffname);
              }
              getFormsById(formid):any{
              return this.http.get<any>(this.Baseurl()+Constants.GET_FORMS+"?id="+formid);
              }

              submitDocument(ReqDocumentModel):any{
                console.log(ReqDocumentModel);
                return this.http.post<any>(this.Baseurl()+Constants.SAVE_SUBMITDOCUEMNT,ReqDocumentModel);
                }
                submitRejectForm(RejectForm):any{
                  return this.http.get<any>(this.Baseurl()+Constants.SUBMIT_REJECT+"?employeeid="+RejectForm.employeeid+"&formid="+RejectForm.formid+"&staffname="+RejectForm.staffname+"&reason="+RejectForm.reason);
                  }

    //To view pdf
    base64ToArrayBuffer(base64) {
      var binaryString = window.atob(base64);
      var binaryLen = binaryString.length;
      var bytes = new Uint8Array(binaryLen);
      for (var i = 0; i < binaryLen; i++) {
         var ascii = binaryString.charCodeAt(i);
         bytes[i] = ascii;
      }
      return bytes;
   }
   saveByteArray(file, byte) {
    var res = file.split(".");
    var filetype=res[1];
    var bytes = new Uint8Array(byte); // pass your byte response to this constructor
  
    var blob=null;
    if(filetype == "jpg" || filetype == "jpeg" || filetype == "png" || filetype == "bmp")
    {
       blob=new Blob([bytes], {type: "application/image"});// change resultByte to bytes
       var link=document.createElement('a');
        link.href=window.URL.createObjectURL(blob);
        link.download=file;
        link.click();
    }
    else
    {
       blob=new Blob([bytes], {type: "application/pdf"});// change resultByte to bytes
       var url = window.URL.createObjectURL(blob);
      window.open(url,'blank');
    }
  };
}
