 <!--  BEGIN NAVBAR  -->
 <div class="header-container fixed-top">
    <header class="header navbar navbar-expand-sm">
        <ul class="navbar-item theme-brand flex-row  text-center">          
            <li class="nav-item theme-logo">
                <a href="index.html">
                    <img src="assets/img/90x90.jpg" class="navbar-logo" alt="logo">
                </a>
            </li>
            <li class="nav-item theme-text">
                <a href="index.html" class="nav-link"> ALASUForms </a>
            </li>
            <li class="nav-item theme-logo">
                <a href="javascript:void(0);" class="sidebarCollapse  text-white" data-placement="bottom"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg></a>
            </li>
        </ul>
        <ul class="navbar-item flex-row ml-md-auto">
               <li class="nav-item dropdown user-profile-dropdown">
                <a href="javascript:void(0);" class="nav-link dropdown-toggle user" id="userProfileDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                    <img src="assets/img/90x90.jpg" alt="avatar">
                    <!-- <span class="text-white pl-2 pr-3">{{firstName}} {{lastName}}</span> -->
                    <span class="text-white pl-2 pr-3" id="username">{{firstName}} {{lastName}}</span>
                </a>
            </li>
        </ul>
    </header>
</div>
<!--  END NAVBAR  -->


