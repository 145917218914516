import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminLoginComponent } from '../../login/admin-login/admin-login.component';
import { LoginService } from '../../shared/login/login.service';
import { StaffService } from '../../shared/staff/staff.service';
import {Title} from "@angular/platform-browser";
declare var $;

@Component({
  selector: 'app-admin-master',
  templateUrl: './admin-master.component.html',
  styleUrls: ['./admin-master.component.css']
})

export class AdminMasterComponent implements OnInit {
  public rowDataProgress:any=[];
  public rowDataCompleted:any=[];
  
 
  constructor(private titleservice:Title,public loginService:LoginService,private route:Router,private staffservice:StaffService) {
    this.titleservice.setTitle("Admin Dashboard");
    
    setTimeout(function(){
     $("#tblSFProgress").DataTable({
      dom: '<"row"<"col-md-12"<"row"<"col-md-6"B><"col-md-6"f> > ><"col-md-12"rt> <"col-md-12"<"row"<"col-md-5"i><"col-md-7"p>>> >',    
      buttons: {          
        buttons: [              
            { extend: 'csv', className: 'btn' },
            { extend: 'excel', className: 'btn' },
            { extend: 'print', className: 'btn' }
        ]
    },
    "oLanguage": {
      "oPaginate": { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
      "sInfo": "Showing page _PAGE_ of _PAGES_",
      "sSearch": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
      "sSearchPlaceholder": "Search...",
     "sLengthMenu": "Results :  _MENU_",
  },
  "order": [[ 3, "desc" ]],
      "stripeClasses": [],
      "lengthMenu": [5, 10, 20, 50],
      "pageLength": 5,
      drawCallback: function () { $('.dataTables_paginate > .pagination').addClass(' pagination-style-13 pagination-bordered mb-5'); }
      
     });   

     $("#tblSFCompleted").DataTable({
      dom: '<"row"<"col-md-12"<"row"<"col-md-6"B><"col-md-6"f> > ><"col-md-12"rt> <"col-md-12"<"row"<"col-md-5"i><"col-md-7"p>>> >',    
      buttons: {          
        buttons: [              
            { extend: 'csv', className: 'btn' },
            { extend: 'excel', className: 'btn' },
            { extend: 'print', className: 'btn' }
        ]
    },
    "oLanguage": {
      "oPaginate": { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
      "sInfo": "Showing page _PAGE_ of _PAGES_",
      "sSearch": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
      "sSearchPlaceholder": "Search...",
     "sLengthMenu": "Results :  _MENU_",
  },
  "order": [[ 3, "desc" ]],
      "stripeClasses": [],
      "lengthMenu": [5, 10, 20, 50],
      "pageLength": 5,
      drawCallback: function () { $('.dataTables_paginate > .pagination').addClass(' pagination-style-13 pagination-bordered mb-5'); }
      
     });
    },3000);

   }

  ngOnInit(): void {
    if (this.loginService.getToken()) {
      this.route.navigate(['dashboard/admin']);
    } else {
      this.route.navigate(['admin']);
    }

    let user=this.loginService.getUser();
    if(user.userRole!="admin"){
      this.route.navigate(['admin']);
    }

    this.getStaffData();

  }
  ViewPDF(file)
  { 
    
    var base64EncodedPDF="";
    this.staffservice.getPdf(file).subscribe(res=>{
      if(res.status==1){
          var sampleArr = this.staffservice.base64ToArrayBuffer(res.data);
          this.staffservice.saveByteArray(file, sampleArr);
      }
    });
  }
  getStaffData(){

    this.staffservice.getForms().subscribe(res=>{debugger
      if(res.status==1){
          for(let i=0;i<res.data.length;i++){
              if(res.data[i].formStatus.toLowerCase() == "counselor accepted" || res.data[i].formStatus.toLowerCase() == "counselor rejected" ){
                let rej={
                  formName: res.data[i].formName,
                  studentID: res.data[i].studentID,
                  name: res.data[i].firstName + res.data[i].lastName,
                  taxYear: res.data[i].taxYear,
                  term: res.data[i].term,
                  formStatus: res.data[i].formStatus,
                  pdfFilepath: res.data[i].pdfFilepath
                }
                this.rowDataCompleted.push(rej);
              }else{
                let pgdt ={
                  formName: res.data[i].formName,
                  studentID: res.data[i].studentID,
                  name: res.data[i].firstName + res.data[i].lastName,
                  taxYear: res.data[i].taxYear,
                  term: res.data[i].term,
                  formStatus: res.data[i].formStatus,
                  pdfFilepath: res.data[i].pdfFilepath
                }
                this.rowDataProgress.push(pgdt);
              }

          }
    }

    });

  }

}