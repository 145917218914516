import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { StaffService } from '../../shared/staff/staff.service';
import { LoginService } from '../../shared/login/login.service';
import Swal from 'sweetalert2';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-staff-resetpassword',
  templateUrl: './staff-resetpassword.component.html',
  styleUrls: ['./staff-resetpassword.component.css']
})
export class StaffResetpasswordComponent implements OnInit {
  public disable=false;
  public submitted = false; 
  public StaffModel: FormGroup;
  public user: any;
  public userid:string=""; 
  public Oldpassword:string;
  public password:string;
  public confirm_password:string;

  constructor(private titleservice:Title,private fb: FormBuilder, private httpClient: HttpClient,
    private staffService: StaffService,private route:ActivatedRoute,private loginService: LoginService,public routing:Router) {
      this.titleservice.setTitle("Reset Password");
    this.StaffModel=fb.group({
      RequestType:'UPDATE',      
      Oldpassword:['',Validators.required],
      password:['',Validators.required],
      confirm_password:['',Validators.required]
    });
  }

  ngOnInit(): void {
    this.user = this.loginService.getUser();
    if(this.user!=null||this.user!=undefined){
      if(this.user.role!="staff"){
        window.location.href="staff";
      }
    }else{
      window.location.href="staff";
    }
    this.userid = this.user.id;
  }
  get f() { return this.StaffModel.controls; }
  savePassword(){
    debugger;
    this.submitted=true;
    if(this.StaffModel.invalid){
      return;
    }
                  if(this.StaffModel.value.RequestType=="UPDATE")
                  {
                    debugger
                     let obj={
                       id:this.userid,
                      // Oldpassword:this.PasswordModel.value.Oldpassword,
                       password:this.StaffModel.value.password,
                      // confirm_password:this.PasswordModel.value.confirm_password,
                     }
                     if(this.StaffModel.value.password!=this.StaffModel.value.confirm_password){
                       Swal.fire({
                        icon: 'warning',
                        title: 'Warning',
                        text: 'Password not matched with Confirm Password'
                      });             
                     }
                     else
                     {
                       this.staffService.UpdatePassword(obj).subscribe(res=>{
                         debugger
                        if(res.status==1){
                          Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: 'Password Updated successfully'
                          });             
                         
                        }else {
                          Swal.fire({
                            icon: 'warning',
                            title: 'Warning',
                            text: 'Error Updating Password'
                          });
                        }
                        this.onCancel();
  
                        });
                      }
                     

                  }
                }

                onCancel() {
                  this.StaffModel = this.fb.group({
                     RequestType:'UPDATE',
                     id:'',
                     password:['',Validators.required],
                     Oldpassword:['',Validators.required],
                     confirm_password:['',Validators.required],                    
                  });
                }
}
