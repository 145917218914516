<app-header></app-header>
<!--  BEGIN MAIN CONTAINER  -->
<div class="main-container" id="container">
    <div class="overlay"></div>
    <div class="search-overlay"></div>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme" *ngIf="ishidestudentsidebar">
        <app-student-sidebar></app-student-sidebar>
    </div>

    <div class="sidebar-wrapper sidebar-theme" *ngIf="ishidestaffsidebar">
        <app-staff-sidebar></app-staff-sidebar>
    </div>
    <!--  END SIDEBAR  -->
    <!--  BEGIN CONTENT AREA  -->
    <div id="content" class="main-content">
        <div class="layout-px-spacing">
            <div class="row layout-top-spacing">      
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            
            <div class="row">
                <div class="col-md-12">
                    <ol *ngIf="ishidestaffsidebar" class="breadcrumb">
                        <li class="breadcrumb-item ml-auto"><a href="dashboard/staff" *ngIf="!link">Dashboard</a></li>
                        <li class="breadcrumb-item active text-primary" *ngIf="!link"><span>Request Documents</span></li>
                    </ol>
                    <ol *ngIf="ishidestudentsidebar" class="breadcrumb">
                        <li class="breadcrumb-item ml-auto"><a href="dashboard/student" *ngIf="link">Dashboard</a></li>
                        <li class="breadcrumb-item active text-primary" *ngIf="link"><span>Attach Documents</span></li>
                    </ol>
                </div>
            </div>
            <div class="card">
                <br id="br1"><br id="br2">
                <div class="card-header bg-secondary"><b class="text-white" *ngIf="!link">Request Documents</b><b class="text-white" *ngIf="link">Attach Documents</b></div>
                <div class="card-body">
                    <form [formGroup]="ReqDocumentModel">                    
                        <div class="form-group" hidden>
                            <label>ID</label>
                            <input class="form-control" type="text" id="id" formControlName="id"
                             readonly>
                        </div>
                        <div class="form-group" hidden>
                            <label>ID</label>
                            <input class="form-control" type="text" id="employeeid" formControlName="employeeid"
                                 readonly>
                        </div>
                                              
                       <div class="row pl-4">
                       <div class="col-md-12">
                        <strong id="title">
                            Please check the appropriate box to request for documents.
                        </strong> 
                       </div>
                       </div>

                       <div class="row pl-4 pb-3">
                         <div class="col-md-6">
                             <div class="row pt-2">
                                 <div class="col-md-12">
                                    <h5>Student</h5>
                                 </div>
                             </div>

                             <div class="row pt-2">
                                <div class="col-md-12">
                                    <div class="custom-control custom-checkbox checkbox-info">
                                        <input type="checkbox" class="custom-control-input" id="Studenttax" [checked]="studentTax"
                                         (change)="onSTax($event)"   required>
                                        <label class="custom-control-label" for="Studenttax"> Student 2020 IRS Tax Return Transcript</label>
                                    </div>
                                   
                                </div>
                            </div>

                            <div class="row pt-2">
                                <div class="col-md-12">
                                   <strong>Previous File : </strong>
                                    <!-- <a href="STUDENTTAX-200115629-2208-2018.pdf" target="blank" formControlName="IRSTaxRetStudentfilepath" class="btn btn-success" title="print"></a>&nbsp;&nbsp; -->
                                   <input type="button" id="IRSTaxRetStudentfilepath" (click)="ViewPDF('IRSTaxRetStudentfilepath')" formControlName="IRSTaxRetStudentfilepath"  />
                                </div>
                            </div>
                            <div class="row pt-1" id="divStudent"  *ngIf="link">
                                <div class="col-md-12">
                            <div class="form-group">
                                <input type="file" id="studentfile" accept="application/pdf,image/jpeg,image/png,image/bmp,image/jpg" (change)="uploadstudentfile($event)" required />
                              </div></div>
                            </div>
                            <div class="row pt-2">
                                <div class="col-md-12">
                                    <div class="custom-control custom-checkbox checkbox-info">
                                        <input type="checkbox" class="custom-control-input" id="studentw21"
                                         (change)="onSW2($event)" [checked]="studentW2" required>
                                        <label class="custom-control-label" for="studentw21">Student W-2</label>
                                    </div>                                   
                                </div>
                            </div>
                            <div class="row pt-1">
                                <div class="col-md-12">
                                   <strong>Previous File : </strong><input type="button" id="StudentW2filepath" (click)="ViewPDF('StudentW2filepath')"
                                   formControlName="StudentW2filepath" />
                                </div>
                            </div>
                            <div class="row pt-1" id="divStudentW2"  *ngIf="link">
                                <div class="col-md-12">
                            <div class="form-group">
                                <input type="file" id="studentfileW2" accept="application/pdf,image/jpeg,image/png,image/bmp,image/jpg" (change)="uploadstudentW2file($event)" required />
                              </div></div>
                            </div>
                         </div>

                         <div id="dvParent" class="col-md-6 pb-2">
                            <div class="row pt-2">
                                <div class="col-md-12">
                                   <h5>Parent</h5>
                                </div>
                            </div>

                            <div class="row pt-2">
                                <div class="col-md-12">
                                    <div class="custom-control custom-checkbox checkbox-info">
                                        <input type="checkbox" class="custom-control-input"
                                         id="parenttax" [checked]="parenttax" (change)="onPTax($event)"  required>
                                        <label class="custom-control-label" for="parenttax"> Parent/Spouse 2020 IRS Tax Return Transcript</label>
                                    </div>                          
                                </div>
                            </div>
                            <div class="row pt-2">
                                <div class="col-md-12">
                                   <strong>Previous File :</strong><input type="button" id="IRSTaxRetParentfilepath" (click)="ViewPDF('IRSTaxRetParentfilepath')"
                                    formControlName="IRSTaxRetParentfilepath" />
                                </div>
                            </div>
                            <div class="row pt-1" id="divParent"  *ngIf="link">
                                <div class="col-md-12">
                            <div class="form-group">
                                <input type="file" id="parentfile" accept="application/pdf,image/jpeg,image/png,image/bmp,image/jpg" (change)="uploadparentfile($event)" required />
                              </div></div>
                            </div>
                            <div class="row pt-2">
                                <div class="col-md-12">
                                    <div class="custom-control custom-checkbox checkbox-info">
                                        <input type="checkbox" class="custom-control-input"
                                         id="parentw21" [checked]="parentW2"
                                         (change)="onPW2($event)" required>
                                        <label class="custom-control-label" for="parentw21">Parent W-2</label>
                                    </div>
                                   
                                </div>
                            </div>
                            <div class="row pt-1">
                                <div class="col-md-12">
                                   <strong>Previous File :</strong>
                                   <input type="button" id="ParentW2filepath" (click)="ViewPDF('ParentW2filepath')"  formControlName="ParentW2filepath" />
                                </div>
                            </div>
                            <div class="row pt-1" id="divParentW2"  *ngIf="link">
                                <div class="col-md-12">
                              <div class="form-group">
                                <input type="file" id="parentfileW2" accept="application/pdf,image/jpeg,image/png,image/bmp,image/jpg" (change)="uploadparentW2file($event)" required />
                              </div></div>
                            </div>
                         </div>
                       </div>
                       <div class="row pl-4 pb-2">
                        <div  class="col-md-8">
                            <textarea rows="4" cols="80" class="form-control" id="Reason" formControlName="Reason"  placeholder="Reason" required></textarea>
                        </div>
                       </div>
                       <div class="row pl-4 pb-2">
                        <div  class="col-md-12">
                            <button type="submit" *ngIf="link" class="btn btn-success" title="Accept Documents" (click)="submitAcceptDocument()" [disabled]="disable">Submit</button>&nbsp;&nbsp;
                            <a href="dashboard/student" id="btncancel"  class="btn btn-danger" title="Cancel">Cancel</a>
                          <button type="submit" *ngIf="!link" class="btn btn-success" title="Request Documents" (click)="saveDocument()" [disabled]="disable">Request Documents</button>&nbsp;&nbsp;
                          <button type="submit" *ngIf="!link" class="btn btn-success"  (click)="saveAcceptDocument()" [disabled]="disable" title="Accept Documents">Accept Documents</button>&nbsp;&nbsp;
                          <a href="dashboard/staff" *ngIf="!link" class="btn btn-danger" title="Cancel">Cancel</a>
                         
                        </div>
                       </div>                 
                 
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
        <app-footer></app-footer>
    </div>
    <!--  END CONTENT AREA  -->

</div>

<!-- END MAIN CONTAINER -->
