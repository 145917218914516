<app-header></app-header>
  <!--  BEGIN MAIN CONTAINER  -->
  <div class="main-container" id="container">

    <div class="overlay"></div>
    <div class="search-overlay"></div>

    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">        
      <app-staff-sidebar></app-staff-sidebar>
    </div>
    <!--  END SIDEBAR  -->
    
    <!--  BEGIN CONTENT AREA  -->
    <div id="content" class="main-content">
    <div class="layout-px-spacing">
      <div class="row layout-top-spacing">     
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div  class="row">
              <div  class="col-md-12">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item ml-auto"><a href="dashboard/staff">Dashboard</a></li>
                  <li class="breadcrumb-item active text-primary" aria-current="page"><span>Staff</span></li>
              </ol>
              </div>
            </div>
            <!-- First Grid Starts-->
                      <div class="row">
                        <div class="col-md-12">
                          <div class="card">
                            <div class="card-header bg-secondary">
                              <span class="text-white"><b>Student Forms Progress</b></span>
                            </div>
                            <div class="card-body">                    
                              <div class="table-responsive">                                
                                <table id="tblSFProgres" class="table table-striped table-bordered" width="100%">
                                  <thead>
                                    <tr>
                                        <th>Form Name</th>                                       
                                        <th>Student ID</th>
                                        <th>Student Name</th>
                                        <th>Tax Year</th>
                                        <th>Term</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                  </thead>
                                    <tbody>
                                        <tr *ngFor="let data of rowDataProgress">
                                          
                                            <!-- <td hidden><input type="hidden" name="id" value={{data.formid}}  ></td> -->
                                            <td>{{data.formName}}</td>
                                            <td><a style="text-decoration: underline;" *ngIf="data.formStatus == 'Student Submitted' || data.formStatus == 'Documents Submitted' || data.formStatus=='Parent/Spouse Accepted'" 
                                              href="fafs?formid={{data.formid}}&from=staff" class="text-primary">{{data.studentID}}</a>
                                            <span *ngIf="data.formStatus == 'Documents Requested'">{{data.studentID}}</span>
                                            </td>
                                            <td>{{data.name}}</td>
                                            <td>{{data.taxYear}}</td>
                                            <td>{{data.term}}</td>
                                            <td>{{data.formStatus}}</td>
                                            <td>
                                              <a (click)="ViewPDF(data.pdfFilepath)" class="btn btn-success" title="print">Print</a> 
                                              &nbsp;&nbsp;                                              
                                              <!-- <a href="requestdocument?Rid={{data.formid}}" class="btn btn-info" title="Request Document">Request Documents</a> -->
                                            </td>
                                          </tr>
                                    </tbody>
                                </table>
                            </div>
                            </div>
                          </div>
                        </div>
                    </div>  

                    <!-- First Grid Ends -->

                    <!-- Second Grid Starts -->
                    <br>
                    <div class="row">
                        <div class="col-md-12">
                          <div class="card">
                            <div class="card-header bg-secondary">
                              <span class="text-white"><b>Student Forms Completed</b></span>
                            </div>
                            <div class="card-body">                            
                              <div class="table-responsive" style="padding-top:15px">
                                <table id="tblSFCompleted" class="table table-striped table-bordered" width="100%">
                                  <thead>
                                    <tr>
                                        <th> Form Name</th>                                       
                                        <th>Student ID</th>
                                        <th>Student Name</th>
                                        <th>Tax Year</th>
                                        <th>Term</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                  </thead>
                                    <tbody>
                                      <tr *ngFor="let data of rowDataCompleted">
                                        <td>{{data.formName}}</td>
                                        <td>{{data.studentID}}</td>
                                        <td>{{data.name}}</td>
                                        <td>{{data.taxYear}}</td>
                                        <td>{{data.term}}</td>
                                        <td>{{data.formStatus}}</td>
                                        <td>
                                          <a (click)="ViewPDF(data.pdfFilepath)" class="btn btn-success" title="print">Print</a>                                        
                                        </td>
                                      </tr>
                                    </tbody>
                                </table>
                            </div>
                            </div>
                          </div>
                        </div>
                    </div>  

                    <!-- Second Grid Ends -->
                
           
        </div>   
        <app-footer></app-footer>    
    </div>
  </div>

    <!--  END CONTENT AREA  -->
  </div>
</div>

<!-- END MAIN CONTAINER -->